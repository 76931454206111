import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../Component/tool-tip';
const MerchantLogo = require("../../../../images/assets/top_merchant.png");

const TopMerchant = (props) => {

  let categorieMerName = [];
  let totalMerAmount = [];
  let topMerchantData = props.topMerchantData ? props.topMerchantData : null

  useEffect(() => {
    {
      topMerchantData && topMerchantData.categoryData && topMerchantData.categoryData.forEach((merchantData) => {
        categorieMerName.push(merchantData.categoryName)
        totalMerAmount.push(merchantData.totalAmount)
      })
    }
  })

  const horizontalChart = {
    series: [{
      data: totalMerAmount
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false
        }
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#7D25F6",
              opacity: 1
            },
            {
              offset: 100,
              color: "#2553F6",
              opacity: 1
            },
          ]
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
          columnWidth: '20%',
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: categorieMerName,
        labels: {
          show: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
        }
      },
      yaxis: {
        labels: {
          show: true
        }
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 260
            },
            yaxis: {
              labels: {
                show: false
              }
            },
            xaxis: {
              labels: {
                show: false,
              }
            },
            dataLabels: {
              enabled: true,
              textAnchor: 'start',
              style: {
                colors: ['#fff'],
                fontSize: '12px'
              },
              formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + "₹ " + val.toLocaleString()
              },
              offsetX: -70,
              dropShadow: {
                enabled: true
              }
            },
            stroke: {
              width: 1,
              colors: ['#fff']
            },
          }
        }
      ]
    },
  };

  return (
    <>
      {topMerchantData && topMerchantData.data != null &&
        <div className='col-6'>
          <div className='account-details-box'>
            <div className="accordion ">
              <div className="acc-info-text mb-0">TOP MERCHANTS SPENDING
                <Tooltip content="Merchants from which you have been shopping"> <i className="bi bi-info-circle boost-info-icon me-1"></i></Tooltip>
              </div>

              <p className="subtitle-heading-chart">{topMerchantData && topMerchantData?.data?.percentageText != null ? '(' + topMerchantData.data.percentageText + ')' : ''}</p>
              <div className='dividers w-100'></div>
              <div className="top-chart-logo">
                <img src={MerchantLogo} className='img-fluid' />
                <div className="top-chart-text">
                  <p className="acc-info-text mb-0">{topMerchantData && topMerchantData?.data?.name != null ? topMerchantData.data.name + ":" + " ₹" + topMerchantData.data.amount : ''}</p>
                  <p className="subtitle-heading-chart">{topMerchantData && topMerchantData?.data?.label != null ? topMerchantData.data.label : ''}</p>
                </div>
              </div>
              <ReactApexChart options={horizontalChart.options} series={horizontalChart.series} type="bar" height={300} />
            </div>
          </div>
        </div>}
    </>
  )
}

export default TopMerchant