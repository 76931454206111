import './Slider.css'
import '../../../App.css'
import EarlyButtons from './early-access/early-access';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import '../../../App.css'
import '../Invitation/Invitation.css'

import { addDocs, firestores, collections } from '../../../script'
const TopBanner = require("../../../images/top_banner.webp");
const MobileCard = require("../../../images/banner_image.png");
const B2BBanner = require("../../../images/b2b_banner.png");
const Banner2 = require("../../../images/banner2.png");
const GenAI = require("../../../images/gen_ai.webp");
const banner2CR = require("../../../images/banner2.webp")
const landingAgent = require("../../../images/landing-agent.webp")
const Banner3 = require("../../../images/banner3.png");
const Banner4 = require("../../../images/banner4.png");
const Banner5 = require("../../../images/banner5.webp");
const experianLogo = require("../../../images/experian_logo.png");
const credDharma = require("../../../images/creddharma.png");
const credMudra = require("../../../images/credmudra.png");
const manibhavanamLogo = require("../../../images/manibhavanam.png");
const BoostAIGif = require("../../../images/boost-ai.gif");
const AaJourney = require("../../../images/aa-journey.gif");
const signzy = require("../../../images/signzy.png");
const insIC1 = require("../../../images/assets/ins_ic1.png");
const insIC3 = require("../../../images/assets/ins_ic3.png");
const insIC2 = require("../../../images/assets/ins_ic2.png");
const AppStore = require("../../../images/appstore.png");
const PlayStore = require("../../../images/play_store.png");
const AAJourneyGIF = require("../../../images/aa-journey.gif");
const LeftCurve = require("../../../images/left_curve.png");
const RightCurve = require("../../../images/right_curve.png");
const CisaLogo = require("../../../images/cisa_logo.png");
const RtArrow = require("../../../images/assets/rt-arrow.png");
const BoostAILogo = require("../../../images/boost-ai.gif");


const startBussiness = (e) => {
    e.preventDefault(); // Stop the default navigation

    window.open('https://enterprise.boost.money', '_blank', 'noopener,noreferrer');
}




function Slider() {
    const [email, SetEmail] = useState('');
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();

    const submit = async () => {
        setUserExists('')
        let rex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setShowError(false);
        if (email === '') {
            setShowError(true)
        } else if (!rex.test(email)) {
            setUserExists('Please enter valid email address');
        } else {
            const docRef = await addDocs(collections(firestores, "emails"), { email });
            SetEmail('');
            setUserExists('We have received your request. Thank you')
            let id = setTimeout(function () {
                setUserExists('')
            }, 2000);

        }
    }

    return (
        <>

            <section className='section-f-root'>
                <div className='d-flex flex-wrap justify-content-between align-content-end'>
                    <div className='section-f-left col-12 col-lg-5 col-md-5 col-sm-12 pt-5'>
                        <div className='pt-5'>
                            <p className='boost-txt'>BOOST AI</p>
                            {/* <p className='credit-score-txt'>Credit score</p> */}
                            <section className='animation ms-0 ps-0 mb-0'>
                                <div className='first'>
                                    <h1 className='credit-score-txt text-uppercase'>Lending Copilot</h1>
                                </div>
                                <div className='second'>
                                    <h1 className='credit-score-txt text-uppercase'>reporting</h1>
                                </div>
                                <div className='third'>
                                    <h1 className='credit-score-txt text-uppercase'>generative models</h1>
                                </div>
                            </section>
                        </div>
                        <div className='pt-4'>

                            <p className='fs-info-txt'>Lend Smarter: AI-Powered Precision<br></br>for Better Decisions!</p>
                        </div>
                        <div className='p-0 m-0 d-flex flex-wrap justify-content-center 
                        justify-content-lg-start justify-content-md-start justify-content-sm-start'>
                            <img src={BoostAILogo} className='small-bm-ai-logo' alt='partner-logo' />
                            <p className='fs-info-txt small-fs-info-txt p-0 m-0 ps-2'> Powered by Boost AI</p>
                        </div>
                        <div className='pt-5'>
                            <div className='d-block flex-column justify-content-center'>
                                <div className='d-flex flex-box flex-row justify-content-center justify-content-lg-start
                                justify-content-sm-start  justify-content-md-start '>
                                    <button id="try_now_enterprise_boost_money" className='btn-start-b' onClick={(e) => { startBussiness(e) }}>
                                        Try now
                                    </button>
                                    <p className='ps-2'></p>
                                    <button id="contact_sales" className='btn-start-sale' onClick={(e) => {
                                        window.location.href = "mailto:sales@boost.money?subject=Inquiry&body=Hello, I would like to know more about...";
                                    }}>
                                        Contact sales
                                    </button>
                                </div>
                                <div className='pb-4'></div>
                                <img src={CisaLogo} className='cisa-logo ps-lg-5 ps-md-3 ps-sm-3 p-0' alt='partner-logo' />
                                <div className='pb-3'></div>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-lg-7 col-md-7 col-sm-12 d-flex align-items-end justify-content-end'>
                        <img src={TopBanner} className='img-b2b-banner' alt='banner' />


                    </div>
                </div>

            </section>


            <div className='defualt-spacing'></div>
            <section className='section-s2 bg-white'>
                <div className='mt-2'></div>
                <div className='d-flex flex-column align-items-start'>
                    <p className='text-uppercase p-0'>
                        Products
                    </p>
                    <p className='section-title p-0'>
                        Boost AI platform for better lending decisions
                    </p>
                </div>
                <div className='d-flex flex-wrap justify-content-between'>

                    <div className='section-s2-sub row p-0 mt-4'>

                        <div className='col-12 col-lg-5 col-md-5 col-sm-12 pt-3 ps-5'>
                            <div className='boost-report-spacing'> </div>
                            <p className='black-txt'>AI Lending Copilot</p>
                            <div className='boost-report-spacing'> </div>
                            <p className='fs-info-txt-dark'>
                                Boost Lending Copilot uses AI for smart lending assistance</p>
                            {/* <span> <img src={experianLogo} className='exp-logo img-fluid' />  </span> </p> */}
                            <div className='boost-report-spacing'></div>
                            <p className='fs-info-txt'>
                                Our proprietary Generative AI-powered Copilot efficiently collects on-demand, consented financial datasets like Bank, Bureau, Tax, and Investments. It features built-in real-time financial intelligence for smarter decision-making.</p>
                        </div>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-none d-lg-block d-md-block d-sm-block'>
                            <img src={landingAgent} className='img-banner2 round-img-bottom-right  round-img-top-left' alt='banner' />
                        </div>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-block d-lg-none d-md-none d-sm-none'>
                            <img src={landingAgent} className='img-banner2 round-img-bottom-right  round-img-top-left' alt='banner' />
                        </div>
                    </div>
                </div>
            </section>


            {/* <div className='defualt-spacing'></div> */}
            <section className='section-s2 bg-white'>
                <div className='d-flex flex-wrap justify-content-between'>
                    {/* <p className='m-0 font-size-14'>TRUSTED BY:</p>
                    <div className='logos-parent d-none d-lg-flex d-md-flex d-sm-flex'>
                        <img src={credDharma} className='partners-logo' alt='partner-logo' />
                        <img src={credMudra} className='partners-logo ' alt='partner-logo' />
                        <img src={manibhavanamLogo} className='partners-logo' alt='partner-logo' />
                        <img src={signzy} className='partners-logo' alt='partner-logo' />
                        <img src={experianLogo} className='partners-logo ' alt='partner-logo' />
                    </div>
                    <div className='logos-parent-mob w-100 d-flex d-lg-none d-md-none d-sm-none'>
                        <div className='d-flex flex-wrap w-100 justify-content-between'>
                            <img src={credDharma} className='partners-logo-mob img-fluid' alt='partner-logo' />
                            <img src={credMudra} className='partners-logo-mob img-fluid' alt='partner-logo' />
                        </div>
                        <div className='d-flex justify-content-between '>
                            <img src={manibhavanamLogo} className='partners-logo-mob small img-fluid' alt='partner-logo' />
                            <img src={signzy} className='partners-logo-mob small img-fluid' alt='partner-logo' />
                            <img src={experianLogo} className='partners-logo-mob small img-fluid' alt='partner-logo' />
                        </div>
                    </div> */}
                    {/* <p className='my-5'></p> */}
                    <div className='section-s2-sub row p-0 mt-4'>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-none d-lg-block d-md-block d-sm-block'>
                            <img src={banner2CR} className='img-banner2 round-img-bottom-left round-img-top-left' alt='banner' />
                        </div>
                        <div className='col-12 col-lg-5 col-md-5 col-sm-12 pt-3'>
                            <div className='boost-report-spacing'> </div>
                            <p className='black-txt'>Boost Report</p>
                            <div className='boost-report-spacing'> </div>
                            <p className='fs-info-txt-dark'>
                                Instant Real-Time Correlated Reporting </p>
                            {/* <span> <img src={experianLogo} className='exp-logo img-fluid' />  </span> </p> */}
                            <div className='boost-report-spacing'></div>
                            <p className='fs-info-txt'>
                                Integrated Credit Bureau and Open Banking/Financial reporting with customer consented data. ML powered Credit Information Report Insights correlated with Boost Transaction Analysis and Index.</p>
                        </div>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-block d-lg-none d-md-none d-sm-none'>
                            <img src={banner2CR} className='img-banner2 round-img-bottom-left round-img-top-left' alt='banner' />
                        </div>
                    </div>
                </div>
            </section>



            {/* <div className='defualt-spacing'></div> */}
            <section className='section-s2 bg-white'>
                <div className='d-flex flex-wrap justify-content-between'>

                    <div className='section-s2-sub row p-0 mt-4'>

                        <div className='col-12 col-lg-5 col-md-5 col-sm-12 pt-3 ps-5'>
                            <div className='boost-report-spacing'> </div>
                            <p className='black-txt'>Generative AI</p>
                            <div className='boost-report-spacing'> </div>
                            <p className='fs-info-txt-dark'>
                                Our proprietary financial models provide unique insights for better decision-making </p>
                            {/* <span> <img src={experianLogo} className='exp-logo img-fluid' />  </span> </p> */}
                            <div className='boost-report-spacing'></div>
                            <p className='fs-info-txt'>
                                Financial Generative AI Insights at your fingertips.
                                Models Fine Tuned on Bureau, Bank, Tax, Investments Datasets.
                                Custom Fine Tuning on your data available.
                                On Premise/Private Cloud.</p>
                        </div>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-none d-lg-block d-md-block d-sm-block'>
                            <img src={GenAI} className='img-banner2 round-img-bottom-right  round-img-top-left' alt='banner' />
                        </div>
                        <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-block d-lg-none d-md-none d-sm-none'>
                            <img src={GenAI} className='img-banner2 round-img-bottom-right  round-img-top-left' alt='banner' />
                        </div>
                    </div>
                </div>
            </section>

            {/* <div className='my-4'></div> */}

            <div className='defualt-spacing'></div>
            <section className='section-s2 bg-white'>
                <div className='mt-3'></div>
                <div className='d-flex flex-column align-items-start'>
                    <p className='text-uppercase p-0'>
                        Advantages
                    </p>
                    <p className='section-title p-0'>
                        Flexible Deployments
                    </p>
                </div>
                <div className='my-4'></div>
                <div className='d-flex flex-wrap row justify-content-between instruction-box-parent'>
                    <div className='instruction-boxes'>
                        <img src={insIC1} className='instruction-img' alt='insIC1' />
                        <div className='my-1'></div>
                        <p className='instruction-title'>Full Stack AA</p>
                        <p className='instruction-sub-title'>We provide full stack AA data processing, analytics with
                            real-time reporting.</p>
                    </div>
                    <div className='instruction-boxes'>
                        <img src={insIC2} className='instruction-img' alt='insIC1' />
                        <div className='my-1'></div>
                        <p className='instruction-title'>Cloud & On-Premise</p>
                        <p className='instruction-sub-title'>Pure Cloud & On Premise Capabilities provides flexible implementations.</p>
                    </div>
                    <div className='instruction-boxes'>
                        <img src={insIC3} className='instruction-img' alt='insIC1' />
                        <div className='my-1'></div>
                        <p className='instruction-title'>Single Integration</p>
                        <p className='instruction-sub-title'>One Single Integration for Credit Bureau and Boost Score reporting.</p>
                    </div>
                </div>

            </section>


            {/* <div className='my-4'></div> */}

            {/* <section className='section-s2 bg-white'>
                <div className='business-integration'>
                    <div className='pt-5'>
                        <p className='section-title'>
                            Business Integrations
                        </p>
                    </div>
                    <div className='my-5'></div>
                    <div className='d-flex col-12'>
                        <div className='col-lg-4 col-md-4 col-sm-12 pe-5 d-flex flex-column align-items-end'>
                            <p className='business-integration-info text-end'>Automated Credit <br></br>Information Report Insights</p>
                            <p className='business-integration-info text-end'>ML Based Insights for <br></br> Improved Decision Making</p>
                            <p className='business-integration-info text-end'>Easy Documentation for <br></br> Quick API Integration</p>
                            <p className='business-integration-info text-end'>Real-time aggregation and <br></br> analysis of Credit Score</p>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center'>
                            <img src={Banner3} className='business-img img-fluid' alt='banner' />
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 ps-5 d-flex flex-column align-items-start'>
                            <p className='business-integration-info '>Real-time Boost Transaction Score <br></br> to predict Credit Worthiness</p>
                            <p className='business-integration-info'>Mobile and Web <br></br> responsive SDK</p>
                            <p className='business-integration-info'>Consumer consented account <br></br> aggregation via Open Banking</p>
                            <p className='business-integration-info'>Automated Consent <br></br> Management</p>
                        </div>
                    </div>
                    <div className='pt-5'></div>
                </div>
            </section > */}

            <div className='defualt-spacing'></div>
            <section className='section-s2 bg-white'>
                <div className='d-flex flex-column align-items-start'>
                    <h6 className='text-uppercase p-0'>
                        Integrations
                    </h6>
                    <p className='section-title p-0'>
                        APIs and SDK
                    </p>
                </div>
                <div className='business-integration'>
                    <div className='corner-parent'>
                        <img src={LeftCurve} alt='left-curve' className='corner-img' />
                    </div>
                    <div className='corner-parent right'>
                        <img src={RightCurve} alt='left-curve' className='corner-img' />
                    </div>
                    <div className='pt-5'>
                        {/* <p className='section-title pad'>
                            Business Integrations
                        </p> */}
                    </div>
                    <div className='pt-3'></div>
                    <div className='d-flex flex-column flex-md-row ps-2 pe-2'>
                        <div className='col-md-4 text-end order-md-1 order-1'>
                            <p className='business-integration-info'>Automated Credit <br></br> Information Report Insights</p>
                            <p className='business-integration-info'>ML Based Insights for <br></br> Improved Decision Making</p>
                            <p className='business-integration-info'>Easy Documentation for<br></br> Quick API Integration</p>
                            <p className='business-integration-info'>Real-time aggregation and <br></br> analysis of Credit Score</p>

                        </div>
                        <div className='col-md-4 text-center order-md-2 order-2 justify-content-center align-items-center d-flex' >
                            <img src={Banner3} className='business-img img-fluid' alt='banner' />
                        </div>

                        <div className='col-md-4 text-start order-md-3 order-3 pt-3 pt-md-0 pt-lg-0 pt-sm-0'>
                            <p className='business-integration-info'>Real-time Boost Transaction Score <br></br> to predict Credit Worthiness</p>
                            <p className='business-integration-info'>Mobile and Web <br></br> responsive SDK</p>
                            <p className='business-integration-info'>Consumer consented account <br></br> aggregation via Open Banking</p>
                            <p className='business-integration-info'>Automated Consent <br></br> Management</p>
                        </div>
                    </div>
                    <div className='pt-5'></div>


                </div>
            </section>

            {/* <div className='my-4'></div> */}
            {/* <div className='defualt-spacing'></div>
            <section className='section-f-root bg-white'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center'>
                        <img src={BoostAIGif} className='ai-img' alt='insIC1' />
                        <div className='my-5'></div>
                        <p className='section-title'>
                            BOOST AI: Your Personal Financial AI
                        </p>
                        <div className='my-5'></div>
                        <img src={Banner4} className='img-fluid' alt='insIC1' />
                    </div>
                </div>
            </section > */}

            {/* <div className='my-4'></div> */}

            {/* <div className='defualt-spacing'></div>
            <section className='section-s2 bg-white'>
                <div className='section-s2-sub row p-0 m-0 mt-3'>
                    <div className='col-12 col-lg-5 col-md-5 col-sm-12'>
                        <div className='pt-4 ps-0 ps-lg-3 ps-md-3 ps-sm-3'>
                            <div className='boost-report-spacing'> </div>
                            <p className='black-txt'>Integrated Account Aggregator</p>
                            <div className='boost-report-spacing'> </div>
                            <p className='fs-info-txt-dark'>
                                Securely share your financial information through Account Aggregator</p>
                            <div className='boost-report-spacing'> </div>
                            <p className='fs-info-txt'>
                                Account Aggregator (AA) is a type of RBI regulated framework that helps an individual securely and digitally access and share information from one financial institution they have an account with to any other regulated financial institution in the AA network.</p>
                            <div className='boost-report-spacing'> </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-flex align-items-end justify-content-end'>
                        <img src={AAJourneyGIF} className='img-fluid round-img-bottom-right' alt='banner' />
                    </div>

                </div>

            </section> */}

            {/* 
            <div className='my-4'></div> */}

            <div className='defualt-spacing'></div>
            <section className='section-s2 bg-white' id='contact'>
                <div className='section-s2-sub row p-0 m-0'>
                    <div className='section-f-left col-12 col-lg-5 col-md-5 col-sm-12 d-flex align-items-center'>
                        <div className='ps-lg-3 ps-md-3 ps-sm-3'>
                            <div className='boost-report-spacing'> </div>
                            <p className='black-txt m-0 p-0'>Get ready for our iOS & Android mobile app. Launching Soon!</p>
                            {/* <p className='black-txt font-size-28'>(Launching Soon)</p> */}
                            <p className='join-wishlist-tag m-0 p-0'>Join our waitlist to get early access.</p>
                            <div className="waitlist-container p-0 m-0 mt-3">
                                <input type="email" placeholder="Enter email address" className="email-input"
                                    value={email}
                                    onChange={(event) => { SetEmail(event.target.value) }} />
                                <button id="join_the_waitlist" className="waitlist-button"
                                    onClick={() => { submit() }}
                                >Join the waitlist</button>
                            </div>
                            <label className="errormsg "></label>
                            {(showError && email === '') && <label className="demo-tetx-black">Please enter email address</label>}
                            {userExists !== '' && <p className="demo-tetx-black mt-2">{userExists}</p>}
                            {/* <div className='d-flex flex-wrap play-logo pb-4'>
                                <img src={AppStore} className='img-btn-dl' alt='banner' />
                                <div className='ms-lg-2'></div>
                                <img src={PlayStore} className='img-btn-dl' alt='banner' />
                            </div> */}
                        </div>
                    </div>
                    <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 text-end'>
                        <img src={Banner5} className='img-fluid img-dl-banner round-img-bottom-right' alt='banner' />
                    </div>
                </div>

            </section>
            <div className='defualt-spacing'></div>
            {/* <div className='headers'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-xl-5'>
                            <div className='text-container mt-2'>
                                <h1 className='h1-large'>BOOST YOUR</h1>
                                <section className='animation ms-0 ps-0 mb-2'>
                                    <div className='first mb-2  h-100'>
                                        <h1 className='h1-large  h-100 gradient-font'>CREDIT SCORE</h1>
                                    </div>
                                    <div className='second h-100'>
                                        <h1 className='h1-large mt-1 h-100 gradient-font'>SAVINGS</h1>
                                    </div>
                                    <div className='third h-100 mt-1'>
                                        <h1 className='h1-large h-100 mt-1 gradient-font'>FINANCES</h1>
                                    </div>
                                </section>
                                <div className='mb-2 mt-sm-5'>
                                    <h1 className='p-large'>Credit Score that you control!</h1>
                                    <h1 className='p-large pb-sm-5'>Take charge of your financial journey.</h1>
                                    <div className='dashboadrd-buttons'>
                                        <EarlyButtons />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-xl-7'>
                            <div className='image-container'>
                                <img className='img-fluid' src={MobileCard} />
                            </div>
                        </div>
                        <div className='mobile-buttons my-4 mt-2'>
                            <EarlyButtons />
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

// function Slider() {
//     return (
//         <>

//             <section className='section-f-root'>
//                 <div className='d-flex flex-wrap justify-content-between align-content-end'>
//                     <div className='section-f-left col-12 col-lg-5 col-md-5 col-sm-12 pt-5'>
//                         <div className='pt-5'>
//                             <p className='boost-txt'>BOOST YOUR</p>
//                             {/* <p className='credit-score-txt'>Credit score</p> */}
//                             <section className='animation ms-0 ps-0 mb-0'>
//                                 <div className='first'>
//                                     <h1 className='credit-score-txt'>CREDIT SCORE</h1>
//                                 </div>
//                                 <div className='second'>
//                                     <h1 className='credit-score-txt'>SAVINGS</h1>
//                                 </div>
//                                 <div className='third'>
//                                     <h1 className='credit-score-txt'>FINANCES</h1>
//                                 </div>
//                             </section>
//                         </div>
//                         <div className='pt-5'>
//                             <p className='fs-info-txt'>Automated Credit Information Report Insights with <br></br> Real-time Boost Transaction Score to predict Credit <br></br> Worthiness</p>
//                         </div>
//                         <div className='pt-5'>
//                             <div className='d-block flex-column justify-content-center'>
//                                 <button className='btn-start-b' onClick={(e) => { startBussiness(e) }}>
//                                     <span>Setup an Account <img src={RtArrow} alt="RtArrow" className='ps-2 rt-arrow' /></span>
//                                 </button>
//                                 <div className='pb-4'></div>
//                                 <img src={CisaLogo} className='cisa-logo ps-lg-5 ps-md-3 ps-sm-3 p-0' alt='partner-logo' />
//                                 <div className='pb-3'></div>
//                             </div>
//                         </div>

//                     </div>
//                     <div className='col-12 col-lg-7 col-md-7 col-sm-12 d-flex align-items-end justify-content-end'>
//                         <img src={B2BBanner} className='img-b2b-banner' alt='banner' />
//                     </div>
//                 </div>

//             </section>


//             <div className='defualt-spacing'></div>
//             <section className='section-s2 bg-white'>
//                 <div className='d-flex flex-wrap justify-content-between'>
//                     {/* <p className='m-0 font-size-14'>TRUSTED BY:</p>
//                     <div className='logos-parent d-none d-lg-flex d-md-flex d-sm-flex'>
//                         <img src={credDharma} className='partners-logo' alt='partner-logo' />
//                         <img src={credMudra} className='partners-logo ' alt='partner-logo' />
//                         <img src={manibhavanamLogo} className='partners-logo' alt='partner-logo' />
//                         <img src={signzy} className='partners-logo' alt='partner-logo' />
//                         <img src={experianLogo} className='partners-logo ' alt='partner-logo' />
//                     </div>
//                     <div className='logos-parent-mob w-100 d-flex d-lg-none d-md-none d-sm-none'>
//                         <div className='d-flex flex-wrap w-100 justify-content-between'>
//                             <img src={credDharma} className='partners-logo-mob img-fluid' alt='partner-logo' />
//                             <img src={credMudra} className='partners-logo-mob img-fluid' alt='partner-logo' />
//                         </div>
//                         <div className='d-flex justify-content-between '>
//                             <img src={manibhavanamLogo} className='partners-logo-mob small img-fluid' alt='partner-logo' />
//                             <img src={signzy} className='partners-logo-mob small img-fluid' alt='partner-logo' />
//                             <img src={experianLogo} className='partners-logo-mob small img-fluid' alt='partner-logo' />
//                         </div>
//                     </div> */}
//                     {/* <p className='my-5'></p> */}
//                     <div className='section-s2-sub row p-0 mt-4'>
//                         <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-none d-lg-block d-md-block d-sm-block'>
//                             <img src={Banner2} className='img-banner2 round-img-bottom-left round-img-top-left' alt='banner' />
//                         </div>
//                         <div className='col-12 col-lg-5 col-md-5 col-sm-12 pt-3'>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='black-txt'>Boost Report</p>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='fs-info-txt-dark'>
//                                 Boost Score is powered by Boost.Money & generated by <span>
//                                     <img src={experianLogo} className='exp-logo img-fluid' />  </span> </p>
//                             <div className='boost-report-spacing'></div>
//                             <p className='fs-info-txt'>
//                                 ML powered Credit Information Report Insights combined with Boost Transaction Score Analysis and Score. <br></br> ML powered Credit Information Report Insights combined with Boost Transaction Score Analysis and Score.</p>
//                         </div>
//                         <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-block d-lg-none d-md-none d-sm-none'>
//                             <img src={Banner2} className='img-banner2 round-img-bottom-left round-img-top-left' alt='banner' />
//                         </div>
//                     </div>
//                 </div>
//             </section>

//             {/* <div className='my-4'></div> */}

//             <div className='defualt-spacing'></div>
//             <section className='section-s2 bg-white'>
//                 <div className='mt-3'></div>
//                 <p className='section-title '>
//                     Advantages for Financial <br></br> Institutions
//                 </p>
//                 <div className='my-4'></div>
//                 <div className='d-flex flex-wrap row justify-content-between instruction-box-parent'>
//                     <div className='instruction-boxes'>
//                         <img src={insIC1} className='instruction-img' alt='insIC1' />
//                         <div className='my-1'></div>
//                         <p className='instruction-title'>Full Stack AA</p>
//                         <p className='instruction-sub-title'>We provide full stack AA data processing, analytics with
//                             real-time reporting.</p>
//                     </div>
//                     <div className='instruction-boxes'>
//                         <img src={insIC2} className='instruction-img' alt='insIC1' />
//                         <div className='my-1'></div>
//                         <p className='instruction-title'>Cloud & On-Premise</p>
//                         <p className='instruction-sub-title'>Pure Cloud & On Premise Capabilities provides flexible implementations.</p>
//                     </div>
//                     <div className='instruction-boxes'>
//                         <img src={insIC3} className='instruction-img' alt='insIC1' />
//                         <div className='my-1'></div>
//                         <p className='instruction-title'>Single Integration</p>
//                         <p className='instruction-sub-title'>One Single Integration for Credit Bureau and Boost Score reporting.</p>
//                     </div>
//                 </div>

//             </section>


//             {/* <div className='my-4'></div> */}

//             {/* <section className='section-s2 bg-white'>
//                 <div className='business-integration'>
//                     <div className='pt-5'>
//                         <p className='section-title'>
//                             Business Integrations
//                         </p>
//                     </div>
//                     <div className='my-5'></div>
//                     <div className='d-flex col-12'>
//                         <div className='col-lg-4 col-md-4 col-sm-12 pe-5 d-flex flex-column align-items-end'>
//                             <p className='business-integration-info text-end'>Automated Credit <br></br>Information Report Insights</p>
//                             <p className='business-integration-info text-end'>ML Based Insights for <br></br> Improved Decision Making</p>
//                             <p className='business-integration-info text-end'>Easy Documentation for <br></br> Quick API Integration</p>
//                             <p className='business-integration-info text-end'>Real-time aggregation and <br></br> analysis of Credit Score</p>
//                         </div>
//                         <div className='col-lg-4 col-md-4 col-sm-12 d-flex flex-column justify-content-center align-items-center'>
//                             <img src={Banner3} className='business-img img-fluid' alt='banner' />
//                         </div>
//                         <div className='col-lg-4 col-md-4 col-sm-12 ps-5 d-flex flex-column align-items-start'>
//                             <p className='business-integration-info '>Real-time Boost Transaction Score <br></br> to predict Credit Worthiness</p>
//                             <p className='business-integration-info'>Mobile and Web <br></br> responsive SDK</p>
//                             <p className='business-integration-info'>Consumer consented account <br></br> aggregation via Open Banking</p>
//                             <p className='business-integration-info'>Automated Consent <br></br> Management</p>
//                         </div>
//                     </div>
//                     <div className='pt-5'></div>
//                 </div>
//             </section > */}

//             <div className='defualt-spacing'></div>
//             <section className='section-s2 bg-white'>
//                 <div className='business-integration'>
//                     <div className='corner-parent'>
//                         <img src={LeftCurve} alt='left-curve' className='corner-img' />
//                     </div>
//                     <div className='corner-parent right'>
//                         <img src={RightCurve} alt='left-curve' className='corner-img' />
//                     </div>
//                     <div className='pt-5'>
//                         <p className='section-title pad'>
//                             Business Integrations
//                         </p>
//                     </div>
//                     <div className='pt-3'></div>
//                     <div className='d-flex flex-column flex-md-row ps-2 pe-2'>
//                         <div className='col-md-4 text-end order-md-1 order-1'>
//                             <p className='business-integration-info'>Automated Credit <br></br> Information Report Insights</p>
//                             <p className='business-integration-info'>ML Based Insights for <br></br> Improved Decision Making</p>
//                             <p className='business-integration-info'>Easy Documentation for<br></br> Quick API Integration</p>
//                             <p className='business-integration-info'>Real-time aggregation and <br></br> analysis of Credit Score</p>

//                         </div>
//                         <div className='col-md-4 text-center order-md-2 order-2 justify-content-center align-items-center d-flex' >
//                             <img src={Banner3} className='business-img img-fluid' alt='banner' />
//                         </div>

//                         <div className='col-md-4 text-start order-md-3 order-3 pt-3 pt-md-0 pt-lg-0 pt-sm-0'>
//                             <p className='business-integration-info'>Real-time Boost Transaction Score <br></br> to predict Credit Worthiness</p>
//                             <p className='business-integration-info'>Mobile and Web <br></br> responsive SDK</p>
//                             <p className='business-integration-info'>Consumer consented account <br></br> aggregation via Open Banking</p>
//                             <p className='business-integration-info'>Automated Consent <br></br> Management</p>
//                         </div>
//                     </div>
//                     <div className='pt-5'></div>


//                 </div>
//             </section>

//             {/* <div className='my-4'></div> */}
//             <div className='defualt-spacing'></div>
//             <section className='section-f-root bg-white'>
//                 <div className='d-flex justify-content-center'>
//                     <div className='text-center'>
//                         <img src={BoostAIGif} className='ai-img' alt='insIC1' />
//                         <div className='my-5'></div>
//                         <p className='section-title'>
//                             BOOST AI: Your Personal Financial AI
//                         </p>
//                         <div className='my-5'></div>
//                         <img src={Banner4} className='img-fluid' alt='insIC1' />
//                     </div>
//                 </div>
//             </section >

//             {/* <div className='my-4'></div> */}

//             <div className='defualt-spacing'></div>
//             <section className='section-s2 bg-white'>
//                 <div className='section-s2-sub row p-0 m-0 mt-3'>
//                     <div className='col-12 col-lg-5 col-md-5 col-sm-12'>
//                         <div className='pt-4 ps-0 ps-lg-3 ps-md-3 ps-sm-3'>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='black-txt'>Integrated Account Aggregator</p>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='fs-info-txt-dark'>
//                                 Securely share your financial information through Account Aggregator</p>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='fs-info-txt'>
//                                 Account Aggregator (AA) is a type of RBI regulated framework that helps an individual securely and digitally access and share information from one financial institution they have an account with to any other regulated financial institution in the AA network.</p>
//                             <div className='boost-report-spacing'> </div>
//                         </div>
//                     </div>
//                     <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 d-flex align-items-end justify-content-end'>
//                         <img src={AAJourneyGIF} className='img-fluid round-img-bottom-right' alt='banner' />
//                     </div>

//                 </div>

//             </section>

//             {/* 
//             <div className='my-4'></div> */}

//             <div className='defualt-spacing'></div>
//             <section className='section-s2 bg-white'>
//                 <div className='section-s2-sub row p-0 m-0'>
//                     <div className='section-f-left col-12 col-lg-5 col-md-5 col-sm-12 d-flex align-items-center'>
//                         <div className='ps-lg-3 ps-md-3 ps-sm-3'>
//                             <div className='boost-report-spacing'> </div>
//                             <p className='black-txt'>Download our app and
//                                 stay on top of your finances.</p>
//                             <p className='black-txt font-size-28'>(Launching Soon)</p>

//                             <div className='d-flex flex-wrap play-logo pb-4'>
//                                 <img src={AppStore} className='img-btn-dl' alt='banner' />
//                                 <div className='ms-lg-2'></div>
//                                 <img src={PlayStore} className='img-btn-dl' alt='banner' />
//                             </div>
//                         </div>
//                     </div>
//                     <div className='col-12 col-lg-7 col-md-7 col-sm-12 p-0 m-0 text-end'>
//                         <img src={Banner5} className='img-fluid img-dl-banner round-img-bottom-right' alt='banner' />
//                     </div>
//                 </div>

//             </section>
//             <div className='defualt-spacing'></div>
//             {/* <div className='headers'>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='col-lg-6 col-xl-5'>
//                             <div className='text-container mt-2'>
//                                 <h1 className='h1-large'>BOOST YOUR</h1>
//                                 <section className='animation ms-0 ps-0 mb-2'>
//                                     <div className='first mb-2  h-100'>
//                                         <h1 className='h1-large  h-100 gradient-font'>CREDIT SCORE</h1>
//                                     </div>
//                                     <div className='second h-100'>
//                                         <h1 className='h1-large mt-1 h-100 gradient-font'>SAVINGS</h1>
//                                     </div>
//                                     <div className='third h-100 mt-1'>
//                                         <h1 className='h1-large h-100 mt-1 gradient-font'>FINANCES</h1>
//                                     </div>
//                                 </section>
//                                 <div className='mb-2 mt-sm-5'>
//                                     <h1 className='p-large'>Credit Score that you control!</h1>
//                                     <h1 className='p-large pb-sm-5'>Take charge of your financial journey.</h1>
//                                     <div className='dashboadrd-buttons'>
//                                         <EarlyButtons />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         <div className='col-lg-6 col-xl-7'>
//                             <div className='image-container'>
//                                 <img className='img-fluid' src={MobileCard} />
//                             </div>
//                         </div>
//                         <div className='mobile-buttons my-4 mt-2'>
//                             <EarlyButtons />
//                         </div>
//                     </div>
//                 </div>
//             </div> */}
//         </>
//     );
// }

export default Slider;