import { Fragment, useEffect, useState } from 'react';
import './consent-revoke-modal.css';
import '../../Sign Up/otp/otp.css';
const CloseIC = require("../../../images/close.png");;
const ExperianLogo = require("../../../images/Bank/experian.png");
const FiuSuccess = require("../../../images/assets/fiu_success.png");
const FiuError = require("../../../images/assets/fiu_error.png");

function ConsentRevokeModal({ onClose, onClickExpFetch, showTypeContent, msgErrorSuccess, onCloseSuccsess,
    onCloseFail
 }) {

    const [isShowMore, setIsShowMore] = useState(false);
    const [isAcceptTerm, setIsAcceptTerm] = useState(false);

    const acceptTermConditon = (event) => {
        setIsAcceptTerm(event.target.checked);
    }
    const readLessMorestyle = {
        WebkitLineClamp: isShowMore ? "6" : "3",
    };
    const btnEnableDisable = {
        opacity: !isAcceptTerm
            ? 0.5 : 1.0,
    };
    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };



    const expContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center'>Revoke Consent</p>
        <div className='mt-2'></div>

        {/* <p className='p-0 m-0 model-exp-sub-title text-center'>Credit report consent expired. Renew consent for latest Experian Credit Report</p> */}
        <div className='my-2'></div>
        <img className="experian-logo-model" src={ExperianLogo} onClick={onClose} />
        <div className='my-4'></div>

        <div>
            <p className='p-0 m-0 model-exp-sub-title'>
                You are about to revoke consent for accessing your Experian Credit Report.
            </p>
            <div className='my-4'></div>
            <p className='p-0 m-0 model-exp-sub-title' >
                This will prevent you from viewing or updating your credit information on this platform.
            </p>
        </div>

        <div className='my-5'></div>

        <div className='d-flex flex-row justify-content-between w-100'>
            <div className='text-center me-1 model-revoke-consent-cancel'
                onClick={() => {
                    onClose();
                }}>Cancel</div>
            <div className='model-revoke-consent-btn text-center ms-1'
                onClick={onClickExpFetch}>Confirm</div>
        </div>

        <div className='mt-2'></div>

    </div>);

    const successExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-green'>Congratulations!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuSuccess} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onCloseSuccsess();
        }}>Done</div>
    </div>)

    const failExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-red'>Something went wrong!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuError} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onCloseFail();
        }}>Done</div>
    </div>)


    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content-experian bg-white" onClick={e => e.stopPropagation()}>
                <img className="modal-close" src={CloseIC} onClick={onClose} />
                <div className='mt-4'></div>

                {showTypeContent == 'EXPERIAN' && expContent}
                {showTypeContent == 'SUCCESS' && successExpContent}
                {showTypeContent == 'ERROR' && failExpContent}

            </div>
        </div>
    );
}
export default ConsentRevokeModal;