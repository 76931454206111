import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../Component/tool-tip';
const ObligationLogo = require("../../../../images/assets/top_obligation.png");

const MonthlyObligation = (props) => {

    let monthYearData = [];
    let monthAmount = [];
    let totalTransaction = [];
    let topMonthlyObligationData = props.topMonthlyObligationData ? props.topMonthlyObligationData : null

    useEffect(() => {
        {
            topMonthlyObligationData && topMonthlyObligationData.monthWiseAmount && topMonthlyObligationData.monthWiseAmount.forEach((monthObligation) => {
                monthAmount.push(monthObligation.amount)
                monthYearData.push(monthObligation.monthYear)
                totalTransaction.push(monthObligation.totalTransaction)
            })
        }
    })

    const barChart1 = {
        series: [{
            name: 'Income',
            data: monthAmount
        }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                    columnWidth: '30%',
                    endingShape: 'rounded',
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    const dataIndex = totalTransaction[opts.dataPointIndex];
                    return dataIndex;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: monthYearData
            },
            fill: {
                opacity: 1,
                type: "gradient",
                gradient: {
                    type: "vertical",
                    shadeIntensity: 1,
                    opacityFrom: 1,
                    opacityTo: 1,
                    colorStops: [
                        {
                            offset: 0,
                            color: "#FECE92",
                            opacity: 1
                        },
                        {
                            offset: 100,
                            color: "#F45D5F",
                            opacity: 1
                        },
                    ]
                }
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "₹ " + val.toLocaleString()
                    }
                }
            }
        },

    };

    return (
        <>
            {topMonthlyObligationData && topMonthlyObligationData.data != null && <div className='row mt-3'>
                <div className='col-12'>
                    <div className='account-details-box'>
                        <div className="accordion ">
                            <div className="acc-info-text mb-0">Monthly obligations
                                <Tooltip content="Credit card utilisation in the past 12 months">
                                    <i className="bi bi-info-circle boost-info-icon me-1"></i></Tooltip>
                            </div>

                            <p className="subtitle-heading-chart">{topMonthlyObligationData && topMonthlyObligationData?.data?.percentageText != null ? '(' + topMonthlyObligationData.data.percentageText + ')' : ''}</p>
                            <div className='dividers w-100'></div>
                            <div className="top-chart-logo">
                                <img src={ObligationLogo} className='img-fluid' />
                                <div className="top-chart-text">
                                    <p className="acc-info-text mb-0">{topMonthlyObligationData && topMonthlyObligationData?.data?.name != null ? topMonthlyObligationData.data.name + ":" + " ₹" + topMonthlyObligationData.data.amount : ''}</p>
                                    <p className="subtitle-heading-chart">{topMonthlyObligationData && topMonthlyObligationData?.data?.label != null ? topMonthlyObligationData.data.label : ''}</p>
                                </div>
                            </div>
                            <ReactApexChart options={barChart1.options} series={barChart1.series} type="bar" height={350} />
                            <p className="chart-bottom-text">MONTHLY OBLIGATIONS CHART</p>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default MonthlyObligation