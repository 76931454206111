import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './bank-statement.css';
import '../../../App.css'
//import Dropdown from 'react-dropdown';
import AsyncSelect from 'react-select/async';
import 'react-dropdown/style.css';
import { scoreByUploadStatement, getBankList } from '../../../redux/actions/dashboard_action';
import Loader from '../../loader/loader';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';
const PoweredBy = require("../../../images/assets/Experian_logo.png");


const BankStatmentForm = (props) => {

    const [selectedBank, setSelectedBank] = useState();
    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedAccount, setSelectedAccount] = useState('SAVING')
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [scoreError, setScoreError] = useState();
    const [showError, setShowError] = useState(false);
    const [titleClpse, setTitleClose] = useState();
    const [bankList, setBankList] = useState();
    const [referenceId, setReferenceId] = useState(props.newReferenceId ? props.newReferenceId : '')
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);

    // const [bankList, setBankList] = useState([{
    //     value: 'AXIS',
    //     label: 'Axis Bank'
    // },
    // {
    //     value: 'HDFC',
    //     label: 'HDFC Bank'
    // },
    // {
    //     value: 'ICICI',
    //     label: 'ICICI Bank'
    // },
    // {
    //     value: 'SBI',
    //     label: 'State Bank of India'
    // },
    // {
    //     value: 'KOTAK',
    //     label: 'Kotak Bank'
    // }])

    useEffect(() => {
        getBankList().then(resp => {
            if (resp.data.statusCode === "SUCCESS") {
                setBankList(resp.data.bankList);
            }
        }).catch((error) => {
            console.log(error);
        });
    }, [])

    const loadOptions = (searchValue, callback) => {
        setTimeout(() => {
            const filterOptions = bankList.filter(option => option.label.toLocaleLowerCase()
                .includes(searchValue.toLocaleLowerCase()))
            callback(filterOptions);
        }, 1000)
    };

    const onBanckChange = (value) => {
        let valData = value ? value.value : "";
        setSelectedBank(valData)
    }

    const backFiuErrorScreen = () => {
        props.setFiuErrorScreen(true);
        props.setFiuErrorModel(true);
        props.setIsFiuOpenDialog(false);
        props.setIsOpenDialog(true);
        props.setFipTspModel(false);
    }

    const closePopup = () => {
        props.setFiuErrorModel(false);
        props.setIsOpenDialog(false);

        props.setFipTspModel(false);
    }


    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const sumbitBankStatement = () => {
        setShowError(false)
        setScoreError(false)
        if (!selectedBank || selectedBank === '' || !selectedFiles || selectedFiles === '') {
            setShowError(true)
        } else {
            var param = new FormData();
            // param.append("files", selectedFiles[0]);
            // param.append("bankName", selectedBank);
            // param.append("accountType", selectedAccount);
            // param.append("isFromSDK", false);
            // param.append("password", pwd);
            param.append("statements[0].bankStmts", selectedFiles[0]);
            param.append("statements[0].bank", selectedBank);
            param.append("statements[0].accountType", selectedAccount);
            param.append("statements[0].password", pwd);

            const uuid = uuidv4();
            setLoader(true)

            scoreByUploadStatement(param, uuid).then(resp => {
                if (resp?.statusCode === "ERROR") {
                    setScoreError(resp?.statusMessage)
                    setReferenceId(props.newReferenceId ? props.newReferenceId : uuid)
                    setLoader(false);
                    return;
                }
                props.getBoostScoreReport()
                props.closeDialog()
                props.setMessage({success: resp.statusMessage })
                props.scrollToTop()
            
                // window.dispatchEvent(new Event('resize'))
                // props.setDialogTitle('Your boost Score')
                // props.setDialogHeight('360px')
                // setScore(resp["score"])
                // setScoreError(undefined)
                // props.setScore(resp["score"])
                // props.setNewBankname(resp?.analysis?.accountDetail ? resp?.analysis.accountDetail.bankName +" Bank | "+ resp?.analysis.accountDetail.accountType +' Account - '+ resp?.analysis.accountDetail.accountNumber.slice(-4) : null)
                // props.setAcHolderName(resp?.analysis?.accountDetail ? resp?.analysis.accountDetail.accountHolderName : null)
                // props.setTransactionPeriod(resp?.analysis?.accountDetail ? resp?.analysis.accountDetail.transactionStartDate +' - '+ resp?.analysis.accountDetail.transactionEndDate : null)
                // props.setAnalysisReportData(resp?.analysis ? resp.analysis : null)
                // props.setIncomeVsExpenseData(resp?.analysis?.incomeVsExpenseAnalysis ? resp.analysis.incomeVsExpenseAnalysis : null)
                // props.setTopSpendingCategoryData(resp?.analysis?.categoryAnalysis ? resp.analysis.categoryAnalysis : null)
                // props.setTopMerchantData(resp?.analysis?.merchantAnalysis ? resp.analysis.merchantAnalysis : null)
                // props.setTopCardUtilizationData(resp?.analysis?.creditCardUtilization ? resp.analysis.creditCardUtilization : null)
                // props.setMonthlyObligationData(resp?.analysis?.obligation ? resp.analysis.obligation : null)
                // props.setInvestmentData(resp?.analysis?.investment ? resp.analysis.investment : null)
                // props.setMessage({ success: resp.statusMessage })
                // props.setIsOpenDialog(false)
                // props.closeDialog()
                // props.scrollToTop()
                // window.scrollTo({
                //     top: 0,
                //     behavior: "smooth",
                // });
                // window.dispatchEvent(new Event('resize'))
                
            }).catch((error) => {
                if (error?.response && error?.response.data) {
                    setScoreError(error.response.data.errorMessage)
                    setReferenceId(props.newReferenceId ? props.newReferenceId : uuid)
                } else if (error.statusCode === "ERROR") {
                    setScoreError(error?.statusMessage)
                    setReferenceId(props.newReferenceId ? props.newReferenceId : uuid)
                } else if (error.errorCode === "STATEMENT_UPLOAD_FAILED" || error.errorCode === "REQUEST_TIMEOUT" || error.errorCode === "INTERNAL_SERVER_ERROR"
                    || error.errorCode === "PASSWORD_ERROR" || error.errorCode === "VALIDATION_ERROR" || error.errorCode === "TRANSACTION_PARSING_ERROR") {
                    setScoreError(error?.errorMessage)
                    setReferenceId(props.newReferenceId ? props.newReferenceId : uuid)
                }
                setLoader(false);
            })
        }
    }

    return (
        <>
            <div>
                {loader && <Loader />}
            </div>
            {/* {props.isErrorState && <div className='col-12'>
                <span id="boot-icon" className="bi bi-arrow-left" onClick={() => {
                    backFiuErrorScreen()
                }}></span>
                <span id="close-icon" className='bi bi-x' onClick={() => {
                    closePopup()
                }}></span>
            </div>} */}
            {props.isErrorState && <div className='d-flex align-items-center justify-content-between'>
                <span id="boot-icon" className="bi bi-arrow-left" onClick={() => {
                    backFiuErrorScreen()
                }}></span>
                <p className='mb-0 text-center upload-title-txt'>Upload Bank Statement</p>
                <span id="close-icon" className='bi bi-x' onClick={() => {
                    closePopup()
                }}></span>
            </div>}

            <div className='container'>
                {score < 0 && <form className="row g-2 needs-validation" noValidate>
                    {/* {props.isErrorState && <div className='col-12'>
                        <p className='mb-0 text-center upload-title-txt'>Upload Bank Statement</p>
                    </div>} */}

                    <div className="col-12">
                        <label htmlFor="validationCustom04" className="label-label mb-2">Bank</label>
                        {/* <Dropdown options={bankList} onChange={(value) => {
                           onBanckChange(value)
                        }} value={selectedBank} placeholder="Select your bank" /> */}

                        <AsyncSelect loadOptions={loadOptions}
                            onChange={onBanckChange}
                            defaultOptions={bankList}
                            isSearchable={true}
                            isClearable={true}
                            placeholder="Select your bank" />
                        {(showError && (!selectedBank || selectedBank === '')) &&
                            <span className="demo-tetx">Please select your bank</span>}
                    </div>

                    <div className="col-12 my-3">
                        <label className="label-label mb-2">Upload File</label>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please select file</span>}
                    </div>
                    <div className='col-12 '>
                        <label className='label-label mb-2'>Password</label>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter Password (Optional)"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>

                    {scoreError && <span className="demo-tetx">{scoreError}
                        {referenceId && <p className="reference-txt pt-2">Reference Id : {referenceId}</p>}
                    </span>}
                    <div className={scoreError ? 'col-12 text-center mt-1' : "col-12 text-center mt-5"}>
                        <button id="submit_bank_statement" className="upload-statement-butoon" type="button" onClick={() => {
                            sumbitBankStatement()
                        }}>Submit</button>
                    </div>
                    {/* <div className='col-12 text-center my-3 align-items-center justify-content-center'>
                        <p className='poweredby-text mb-0'>Powered by</p>
                        <img src={PoweredBy} width='120' />
                    </div> */}
                </form>}
            </div>
        </>
    )
}

export default BankStatmentForm;