
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../Component/tool-tip';
const CreditCardLogo = require("../../../../images/assets/credit_card.png");

const CreditCard = (props) => {

  let cardData = [];
  let cardCategoriesName = [];
  let topCardUtilizationData = props.topCardUtilizationData ? props.topCardUtilizationData : null

  useEffect(() => {
    {
      topCardUtilizationData && topCardUtilizationData.monthWiseAmount && topCardUtilizationData.monthWiseAmount.forEach((cardUtilization) => {
        cardData.push(cardUtilization.amount)
        cardCategoriesName.push(cardUtilization.monthYear)
      })
    }
  })

  const lineChart = {

    series: [{
      name: "Transcation",
      data: cardData
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      fill: {
        opacity: 1,
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 1,
          colorStops: [
            {
              offset: 0,
              color: "#4243F1",
              opacity: 1
            },
            {
              offset: 100,
              color: "#4243F1",
              opacity: 1
            },
          ]
        }
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "₹ " + val.toLocaleString()
          }
        }
      },
      xaxis: {
        type: 'category',
        categories: cardCategoriesName
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 300
            },
          }
        }
      ]

    },
  };

  return (
    <>
      {topCardUtilizationData && topCardUtilizationData.data != null && <div className='col-6 pb-category'>
        <div className='account-details-box'>
          <div className="accordion ">
            <div className="acc-info-text mb-0 cursor-pointer">Credit Card Utilization
              <Tooltip content="Credit card utilisation in the past 12 months"> <i className="bi bi-info-circle boost-info-icon me-1"></i></Tooltip></div>

            <p className="subtitle-heading-chart">{topCardUtilizationData && topCardUtilizationData?.data?.percentageText != null ? '(' + topCardUtilizationData.data.percentageText + ')' : ''}</p>
            <div className='dividers w-100'></div>
            <div className="top-chart-logo">
              <img src={CreditCardLogo} className='img-fluid' />
              <div className="top-chart-text">
                <p className="acc-info-text mb-0">{topCardUtilizationData && topCardUtilizationData?.data?.name != null ? topCardUtilizationData.data.name + ":" + " ₹" + topCardUtilizationData.data.amount : ''}</p>
                <p className="subtitle-heading-chart">{topCardUtilizationData && topCardUtilizationData?.data?.label != null ? topCardUtilizationData.data.label : ''}</p>
              </div>
            </div>
            <ReactApexChart className="resize-chart" options={lineChart.options} series={lineChart.series} type="line" height={300} />
          </div>
        </div>
      </div>}
    </>
  )
}

export default CreditCard