import ReactGA from "react-ga4";
import config from '../config.json'

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize(config?.googleAnalyticsID); // Replace with your GA Measurement ID

// Common function to track events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

// Function to track button clicks
export const handleButtonClick = (event) => {
  const id = event.target.id || "unknown-button";
  trackEvent("Button", "Clicked", id);
};

// Function to track anchor clicks
export const handleAnchorClick = (event) => {
  const id = event.target.id || "unknown-anchor";
  trackEvent("Anchor", "Clicked", id);
};