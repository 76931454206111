// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.savings-boxes .insights-card-main-text{
    font-size: 12px !important;
}
.boxes-h{
    height: 220px !important;
}
.savings-boxes .card-month{
    font-size: 12px;
}

.savings-boxes .insights-sub-main-box{
    /* width: 200px !important; */
}

.saving-display{
    display: -webkit-inline-box;
}

.savings-boxes .insights-main-first{
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 7px;
    
}

.saving-bottom-boxes {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 36%;
}
@media only screen and (min-width: 400px){
  .w-100-alt{
    width: 100% !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
   .savings-boxes .first-card-class{
        display: flex !important;
        justify-content: center !important;
    }
 
}

.insights-imag-box-saving-ic{
    width: 36px;
}

/* @media  only screen and (width: 1024px) and (min-width: 1024px) {
  .sub .col-lg-3 {
    flex: 0 0 auto;
    width: 40% !important;
  }
  .insights-box-main-title{
    padding-top: 10px !important;
    margin: 0px !important;
    font-size: 12px;
  }
} */

.scroll-container {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  white-space: nowrap; /* Prevent wrapping */
}

`, "",{"version":3,"sources":["webpack://./src/pages/Savings/savings.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,WAAW;AACf;AACA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;GACG;QACK,wBAAwB;QACxB,kCAAkC;IACtC;;AAEJ;;AAEA;IACI,WAAW;AACf;;AAEA;;;;;;;;;;GAUG;;AAEH;EACE,aAAa;EACb,gBAAgB,EAAE,gCAAgC;EAClD,iCAAiC,EAAE,4BAA4B;EAC/D,mBAAmB,EAAE,qBAAqB;AAC5C","sourcesContent":[".savings-boxes .insights-card-main-text{\n    font-size: 12px !important;\n}\n.boxes-h{\n    height: 220px !important;\n}\n.savings-boxes .card-month{\n    font-size: 12px;\n}\n\n.savings-boxes .insights-sub-main-box{\n    /* width: 200px !important; */\n}\n\n.saving-display{\n    display: -webkit-inline-box;\n}\n\n.savings-boxes .insights-main-first{\n    background: #FFFFFF;\n    border: 1px solid #D1D1D1;\n    border-radius: 7px;\n    \n}\n\n.saving-bottom-boxes {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    height: 36%;\n}\n@media only screen and (min-width: 400px){\n  .w-100-alt{\n    width: 100% !important;\n  }\n}\n\n@media only screen and (min-width: 577px) and (max-width: 768px) {\n   .savings-boxes .first-card-class{\n        display: flex !important;\n        justify-content: center !important;\n    }\n \n}\n\n.insights-imag-box-saving-ic{\n    width: 36px;\n}\n\n/* @media  only screen and (width: 1024px) and (min-width: 1024px) {\n  .sub .col-lg-3 {\n    flex: 0 0 auto;\n    width: 40% !important;\n  }\n  .insights-box-main-title{\n    padding-top: 10px !important;\n    margin: 0px !important;\n    font-size: 12px;\n  }\n} */\n\n.scroll-container {\n  display: flex;\n  overflow-x: auto; /* Enable horizontal scrolling */\n  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */\n  white-space: nowrap; /* Prevent wrapping */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
