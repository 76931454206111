// import { initializeApp } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-app.js";
// import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-analytics.js";
// import { initializeAppCheck, ReCaptchaV3Provider } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-app-check.js";
// import { getFirestore, collection, addDoc } from "https://www.gstatic.com/firebasejs/9.17.1/firebase-firestore.js";

import { getAnalytics } from "@firebase/analytics";
import { initializeApp } from "@firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "@firebase/app-check";
import { addDoc, collection, getFirestore } from "@firebase/firestore";

{/* TODO: Add SDKs for Firebase products that you want to use */ }
{/* https://firebase.google.com/docs/web/setup#available-libraries */ }

{/* Your web app's Firebase configuration */ }
{/* For Firebase JS SDK v7.20.0 and later, measurementId is optional */ }
const firebaseConfig = {
    apiKey: "AIzaSyDsPVbhqa5lrslaxpLPU9ja8YDJlky6rIQ",
    authDomain: "boostmoney-email-subscription.firebaseapp.com",
    projectId: "boostmoney-email-subscription",
    storageBucket: "boostmoney-email-subscription.appspot.com",
    messagingSenderId: "830845636352",
    appId: "1:830845636352:web:ca83ff99ff82e7c72aade5",
    measurementId: "G-RDPZSM4DSX"
};

{/* Initialize Firebase */ }
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

{/* Initialize app check */ }
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LcxC70kAAAAAMEmGCG3l3IjB94Z_NHyuH6-9yip'),
    //  Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});

{/* Initialize Firestore and App Check */ }
const firestores = getFirestore();
const addDocs = addDoc;
const collections = collection;

export { firestores, addDocs, collections }