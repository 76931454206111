import httpClient, { setAuthHeader } from "../../config/api";
import { LOGOUT, } from "../constants";
import AsyncStorage from '@react-native-async-storage/async-storage';

const loginAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/login',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const otpVerification = (data,headerDt) =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/otp/verify',
      data: data,
      headers : headerDt
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error);
      });
  });

const resendOTPAction = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/resend/otp',
      data: data,
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });

const emailVerification = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/url',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response);
      });
  });

const authCode = data =>
  new Promise((resolve, reject) => {

    httpClient({
      method: 'POST',
      url: 'api/v1/user/google/oauth/code',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    })
      .catch(error => {
        reject(error.response.data);
      });
  });

  const emailParseData = data =>
  new Promise((resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/email/parse/data',
      data: data,
    }).then(resp => {
      resolve(resp.data);
    }).catch(error => {
        reject(error.response);
      });
  });

const logOut = () => dispatch => {
  return new Promise(async (resolve, rejects) => {
    try {
      dispatch({
        type: LOGOUT,
        subtype: 'SUCCESS'
      });
      await AsyncStorage.removeItem('token')
      localStorage.removeItem("messagesAI")
      setAuthHeader()
      resolve(true);
    } catch (error) {
      rejects(false);
    }
  });
};

const registerUser = (userInfo,uuid) =>
  new Promise(async (resolve, reject) => {
    httpClient({
      method: 'POST',
      url: 'api/v1/user/register',
      data: userInfo,
      headers : {
        'x-reference-id' : uuid
      }
    })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        reject(error);
      });
  });


export {
  loginAction,
  logOut,
  registerUser,
  otpVerification,
  emailVerification,
  emailParseData,
  authCode,
  resendOTPAction
}