// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experian-term-heading {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.072%; /* 26.412px */
}
.experian-term-content {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 165.072%; /* 26.412px */
}
.content-section {
    overflow-y: auto;
    height: 500px;
}
    
.content-section::-webkit-scrollbar {
    background: #fff;
    width: 5px;
    height: auto;
}
.content-section::-webkit-scrollbar-thumb {
    background: #c5c5c5;
}
.content-section-raise-dispute{
  overflow-y: auto;
  height: 360px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Sign Up/experian_term_condition/experian_term.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB,EAAE,aAAa;AACtC;AACA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".experian-term-heading {\n  color: #000;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 165.072%; /* 26.412px */\n}\n.experian-term-content {\n  color: #000;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 165.072%; /* 26.412px */\n}\n.content-section {\n    overflow-y: auto;\n    height: 500px;\n}\n    \n.content-section::-webkit-scrollbar {\n    background: #fff;\n    width: 5px;\n    height: auto;\n}\n.content-section::-webkit-scrollbar-thumb {\n    background: #c5c5c5;\n}\n.content-section-raise-dispute{\n  overflow-y: auto;\n  height: 360px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
