import { Fragment, useEffect, useState } from 'react';
import './experian-model.css';
import '../../Sign Up/otp/otp.css';
const CloseIC = require("../../../images/close.png");;
const ExperianLogo = require("../../../images/Bank/experian.png");
const FiuSuccess = require("../../../images/assets/fiu_success.png");
const FiuError = require("../../../images/assets/fiu_error.png");

function ExperianModal({ onClose, onClickExpFetch, showTypeContent, msgErrorSuccess }) {

    const [isShowMore, setIsShowMore] = useState(false);
    const [isAcceptTerm, setIsAcceptTerm] = useState(false);

    const acceptTermConditon = (event) => {
        setIsAcceptTerm(event.target.checked);
    }
    const readLessMorestyle = {
        WebkitLineClamp: isShowMore ? "6" : "3",
    };
    const btnEnableDisable = {
        opacity: !isAcceptTerm
            ? 0.5 : 1.0,
    };
    const toggleReadMoreLess = () => {
        setIsShowMore(!isShowMore);
    };

    const expContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center'>Fetch Credit Report</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 model-exp-sub-title text-center'>Credit report consent expired. Renew consent for latest Experian Credit Report</p>
        <div className='my-4'></div>
        <img className="experian-logo-model" src={ExperianLogo} onClick={onClose} />
        <div className='my-4'></div>

        <Fragment>
            <div className="modal-term-content d-flex flex-row align-items-start pt-2">
                <input
                    className='otp-checkbox'
                    type="checkbox"
                    id="flexCheckDefault"
                    value={isAcceptTerm}
                    onChange={acceptTermConditon}
                />
                <div className='ps-2 pt-0'>
                    <label
                        className="experian-term-line"
                        style={readLessMorestyle}
                    >
                        I hereby consent to Boost.Money being appointed as my
                        authorized representative to receive my Credit Information
                        from Experian for the purpose of Credit Assessment to advise
                        me on the financial journey, on ongoing basis for not
                        exceeding a period of six (6) months.
                    </label>
                    <label className="term-txt-otp">
                        I also agree to{" "}
                        <a href="/experian_term">
                            Experian’s Terms and Conditions.
                        </a>
                    </label>

                    <div
                        className="read-more pt-1"
                        onClick={toggleReadMoreLess}>
                        {isShowMore ? "Read less" : "Read more"}
                    </div>
                </div>
            </div>
        </Fragment>

        <div className='my-3'></div>
        <div className='model-fetch-exp-btn text-center'
            style={btnEnableDisable} onClick={isAcceptTerm ? onClickExpFetch : null}>Fetch Credit Report</div>

    </div>);

    const successExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-green'>Congratulations!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuSuccess} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onClose();
        }}>Done</div>
    </div>)

    const failExpContent = (<div className='d-flex flex-column align-items-center'>
        <p className='p-0 m-0 model-exp-title text-center title-red'>Something went wrong!</p>
        <div className='mt-3'></div>
        <p className='p-0 m-0 px-5 model-exp-sub-title text-center'>{msgErrorSuccess}</p>
        <div className='my-4'></div>
        <img className="img-fluid fiu-success-img" src={FiuError} onClick={onClose} />
        <div className='my-5'></div>
        <p className='p-0 m-0 px-2 model-exp-sub-title text-center '>We are redirecting back to Home</p>
        <div className='my-3'></div>
        <div type='button' className='model-fetch-exp-btn text-center' onClick={() => {
            onClose();
        }}>Done</div>
    </div>)


    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content-experian bg-white" onClick={e => e.stopPropagation()}>
                <img className="modal-close" src={CloseIC} onClick={onClose} />
                <div className='mt-4'></div>

                {showTypeContent == 'EXPERIAN' && expContent}
                {showTypeContent == 'SUCCESS' && successExpContent}
                {showTypeContent == 'ERROR' && failExpContent}

            </div>
        </div>
    );
}
export default ExperianModal;