import React from 'react';
import '../../../App.css'
import './Footer.css'
import ChatIcon from '../../Boost-AI/Chat-Icon/chat-icon';
const BoostWhiteLogo = require("../../../images/boost-logo-white.png");
const FbLogo = require("../../../images/Facebook.png");
const LinkLogo = require("../../../images/Linkedin.png");
const TwitterLogo = require("../../../images/Twitter.png");

function LandingFooter() {

    return (
        <>
            {/* <ChatIcon></ChatIcon> */}

            <section className='copyright'>
                <div className='section-s2'>
                    <div className='d-flex flex-column py-1'>
                        <div className='d-flex flex-wrap justify-content-between'>
                            <div className=''>
                                <img src={BoostWhiteLogo} className='bm-white-logo' />
                            </div>
                            {/* <div className='d-flex flex-wrap align-items-end justify-content-end
                                    pt-5 pt-lg-0 pt-md-0 pt-sm-0'>
                                <div className='text-start'>
                                    <p className='footer-text'>Company</p>
                                    <p className='pt-2'></p>
                                    <p className='footer-sub-text'>About Us</p>
                                </div>
                                <div className='ps-4'></div>
                                <div className='text-start'>
                                    <p className='footer-text'>Contact</p>
                                    <p className='pt-2'></p>
                                    <p className='footer-sub-text'>support@boost.money</p>
                                </div>
                                <div className='ps-4'></div>
                                <div className='text-start'>
                                    <p className='footer-text'>Quick Links</p>
                                    <p className='pt-2'></p>
                                    <p className='footer-sub-text'>Blogs</p>
                                </div>
                            </div> */}
                        </div>

                        {/* <div className='d-flex flex-row pt-4'>
                            <img src={LinkLogo} className='social-icons' />
                            <p className='ps-4'></p>
                            <img src={FbLogo} className='social-icons' />
                            <p className='ps-4'></p>
                            <img src={TwitterLogo} className='social-icons' />
                        </div> */}
                        <div className='footer-line mt-5'></div>
                        <div className='d-lg-flex d-sm-flex d-md-flex justify-content-between flex-wrap'>
                            <div className='pt-4 text-center'>
                                <div className="term">
                                    <a className="p-small statement" href='/terms'>Terms & Conditions</a>
                                    <a className="p-small statement" href='/privacypolicy'>  |  Privacy Policy</a>
                                    <a className="p-small statement" href='/privacypolicy#google-api-disclosure'>  |  Google API Disclosure</a>
                                </div>
                            </div>
                            <div className='pt-4 text-center'>
                                <a className="p-small statement ">Copyright © {(new Date().getFullYear())} Boost.Money</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    );
}

export default LandingFooter;