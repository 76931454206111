import React, { useState } from 'react';
import Tabs from './tabs';
import { useNavigate } from 'react-router-dom';

export default function TabComponent(props) {
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);

    function changeTabIndex(i) {
        if (i == 0) {
            navigate('/dashboard')

        } else {
            navigate('/boostscore')
        }
        setTabIndex(i);
    }

    return (
        <Tabs activeIndex={props.index ?? 0} onTabChange={changeTabIndex} />
    );
}