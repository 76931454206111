import { useEffect } from 'react';
import '../../App.css'
import './coming-soon.css'
import Footer from '../landing-page/Footer/Footer';
import { Link } from 'react-router-dom';
const Logo = require("../../images/assets/Logo.png");
const Coming = require("../../images/assets/coming.png");

const ComingSoon = (props) => {

    return (
        <>
            
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            {/* logo-image */}
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color take-charge'>
                                            Take charge of your financial journey
                                    </h1>
                                    {/* <p>
                                        There is so much you can do to improve your score..
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box text-center pt-3 col-md-8 offset-md-2'>
                                    <h1 className='mb-md-0 text-center'>
                                            Coming Soon
                                    </h1>
                                    <p className='stay-tune mt-md-2'>
                                             Stay Tuned
                                    </p>
                                    <div className='form-input-box coming-box'>
                                    <div className='col-12'>
                                        <img src={Coming} className='img-fluid' />
                                    </div>
                                    <div className='mt-4 mb-2'>
                                            <Link to='/' className='back-home'>Back to Home</Link>
                                    </div>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    )
}

export default ComingSoon