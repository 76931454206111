import '../../../App.css';
import Header from '../../landing-page/header/header';
import Footer from '../../landing-page/Footer/Footer';
import Term from '../../landing-page/Component/terms';
const Logo = require("../../../images/assets/Logo.png");

function Terms() {

    return (
        <>
            <Header></Header>
            <Term openFromBM={true}></Term>
            <Footer></Footer>
        </>
    );
}

export default Terms;