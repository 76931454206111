import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import config from '../../../config.json';
import '../../../App.css';
import { registerUser } from '../../../redux/actions/auth_action';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import ExpLogoFooter from '../../landing-page/Component/exp_logo_footer'
import { v4 as uuidv4 } from 'uuid';
import './createanaccount.css';

const Logo = require("../../../images/assets/Logo.png");


function CreateAccount() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [isAcceptTerm, setIsAccepTerm] = useState(false)
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();
    const [fillupdetails, setFillupdetails] = useState();
    const navigate = useNavigate();

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        const pathName = window.location.pathname;
        if(config?.env === "PROD" && pathName === "/signup"){
            navigate('/comingsoon');
        }

        if (user?.access_token) {
            navigate('/dashboard');
        }
    }, [])

    const loginPage = () => {
        navigate('/login-prod');
    }

    const save = () => {
        setUserExists('');
        setFillupdetails('');
        setShowError(false);
        if (firstName === '' || lastName === '' || mobileNumber === '' || mobileNumber.length !== 10) {
            setShowError(true)
        }
        else if (!isAcceptTerm) {
            setFillupdetails("Please accept terms and privacy policy")
        } else {
            const uuid = uuidv4();
            const param = {
                "firstName": firstName,
                "lastName": lastName,
                "mobileNumber": mobileNumber,
                "tncAccepted": "YES"
            };
            setLoader(true)
            registerUser(param, uuid).then(resp => {
                setLoader(false);
                if (resp.statusCode === "OTP_SENT") {
                    navigate('/otp', { state: { mobileNumber: mobileNumber, from: 'register', referenceId: uuid, otpReferenceId : resp.otpReferenceId } });
                }
            }).catch(error => {
                if (error && error?.response && error?.response?.data) {
                    setUserExists(error?.response.data.errorMessage)
                } else {
                    setUserExists("Something went wrong!")
                }
                setLoader(false);
            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            save()
        }
    };

    const btnEnableDisable = {
        opacity: firstName == ''
            || lastName == '' || (mobileNumber.length !== 10 || mobileNumber == '') ||
            (!isAcceptTerm) ? 0.5 : 1.0,
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5 '>
                            <a className='navbar-brand  logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-2'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <h1 >
                                    Start your <span className='boost-text'>boost</span> journey
                                </h1>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row align-items-center'>
                                <div className='form-box col-md-8 py-2 offset-md-2'>
                                    <h1 className='text-start'>
                                        Create An Account
                                    </h1>
                                    <div className='form-input-box mt-md-4'>
                                        <form>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>First Name</label>
                                                </div>
                                                <div>
                                                    <input type="text" name='fname' id='fname' className='input-box form-control' value={firstName} onChange={(e) => { setFirstName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && firstName === '') && <span className="demo-tetx py-0 my-0">Please enter your first name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Last Name</label>
                                                </div>
                                                <div>
                                                    <input type="text" id='lname' className='input-box form-control' value={lastName} onChange={(e) => { setLastName(e.target.value) }} onKeyDown={handleKeyDown} />
                                                    {(showError && lastName === '') && <span className="demo-tetx">Please enter your last name</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className=''>
                                                    <label className='label-label'>Mobile Number</label>
                                                </div>
                                                <div>
                                                    <input type="tel" className='input-box form-control' value={mobileNumber}
                                                        onKeyDown={(e) => {
                                                            setMobileNumber(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onChange={(e) => {
                                                            setMobileNumber(e.target.value)
                                                        }} minLength="10" maxLength="10" autoComplete='off' />
                                                    {(showError && mobileNumber === '') && <span className="demo-tetx">Please enter your mobile number</span>}
                                                    {(showError && (mobileNumber.length !== 10 && mobileNumber !== '')) && <span className="demo-tetx">Please enter valid mobile number</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-1">{userExists}</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value={isAcceptTerm} id="flexCheckDefault" onChange={(e) => setIsAccepTerm(e.target.checked)} />
                                                    <label className="form-check-label" htmlFor='flexCheckDefault'>
                                                        I agree to all the
                                                    </label>
                                                    <span>
                                                        <Link className='policy-page' to={'/terms'}>
                                                            <span> Terms </span>
                                                        </Link>
                                                        <span className='and-text'>and</span>
                                                        <span>
                                                            <Link to={'/privacypolicy'} className='policy-page'> <span>Privacy policy </span> </Link>
                                                        </span>
                                                    </span>
                                                </div>
                                                {fillupdetails !== '' && <p className='demo-tetx mt-1'>{fillupdetails}</p>}
                                            </div>
                                            <div className='form-inner-box'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-sm-12 col-md-6 order-2 order-sm-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span className='any-account-text'>Have an account? </span><span className='login-text' onClick={() => {
                                                                loginPage()
                                                            }}>Login</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-6 order-1 order-sm-2'>
                                                        <div className='text-center text-sm-center text-md-end'>
                                                            <button type='button' className='next-button'
                                                                style={btnEnableDisable}
                                                                onClick={() => {
                                                                    save()

                                                                }} >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="mt-3">
                                            <ExpLogoFooter />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default CreateAccount;