import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react'
import './header-new.css';
import '../../landing-page/landing-page.css';
import '../../../App.css'

import { useSelector } from 'react-redux';
import config from './../../../config.json'
import { Fragment } from 'react';
const Logo = require("../../../images/assets/MobileViewLogo.png");
const LogoProd = require("../../../images/assets/Logo.png");
const CloseIC = require("../../../images/close.png");
const MenuIC = require("../../../images/menu.png");

function Header() {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    useEffect(() => {
        if (window.location.href.indexOf("#contact") > -1) {
            window.location.replace("/#contact");
        }
    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    function MenuList({ isOpen }) {
        return (
            <div>
                <ul className={`menu-list pt-4 ${isOpen ? 'open' : 'close'}`}>
                    <a href='https://enterprise.boost.money/' target="_blank" className='link-head'>
                        Developers
                    </a>
                    <a href='#contact' className='link-head pt-3' >
                        Contact Us
                    </a>
                    <a href='https://enterprise.boost.money/dsa/pricing' target="_blank" className='link-head pt-3' >
                        DSA Boost
                        <span class="live-txt">INDIA LIVE</span>
                    </a>

                </ul>
                <div className={`justify-content-between align-items-center pt-4 ${isOpen ? 'd-flex ' : 'd-none'}`}>
                    <Link className='btn-header' target="_blank" to='https://enterprise.boost.money'>Lenders</Link>
                    <div className='ms-2'></div>
                    <Link className='btn-header btn-header-white' to={user?.access_token ? '/dashboard' : '/signup'}>Consumers</Link>
                </div>
            </div>

        );
    }



    return (<>
        <section>
            <Fragment>
                <div className='header-new row'>
                    <a className='col-md-3 col-sm-3 col-12 header-menus'
                        href='/' >
                        <img src={Logo} className='app-header-logo img-fluid' />
                    </a>

                    <div className='justify-content-center align-items-center col-md-5 col-sm-5 col-12 header-menus'>
                        <a href='https://enterprise.boost.money/' target="_blank" className='link-head'>
                            Developers
                        </a>
                        <div className='ms-4'></div>
                        {/* <a href='/' className='link-head'>
                            Blogs
                        </a> */}
                        {/* <div className='ms-4'></div> */}
                        <a href='#contact' className='link-head' >
                            Contact Us
                        </a>
                        <div className='ms-4'></div>
                        <a href='https://enterprise.boost.money/dsa/pricing' target="_blank" className='link-head' >
                            DSA Boost
                            <span class="live-txt">INDIA LIVE</span>
                        </a>
                    </div>

                    <div className='justify-content-end align-items-center col-md-4 col-sm-4 col-12 header-menus'>
                        {/* <Link className='btn-header' to='/signup'>{user?.access_token ? 'Lenders' : 'Sign Up/Login'}</Link>
                        <div className='ms-2'></div>
                        <Link className='btn-header btn-header-white' to='/signup'>{user?.access_token ? 'Consumers' : 'Sign up for Early Access '}</Link>
                     */}
                        <Link className='btn-header' target="_blank" to='https://enterprise.boost.money'>Lenders</Link>
                        <div className='ms-2'></div>
                        <Link className='btn-header btn-header-white' target="_self"
                            to={user?.access_token ? '/dashboard' : '/signup'} >Consumers</Link>
                    </div>

                    <div className='d-lg-none d-md-none d-sm-none'>
                        <div className='w-100 d-flex justify-content-between flex-row align-items-center'>
                            <a href='/' className=''>
                                <img src={Logo} className='img-fluid app-header-logo ' />
                            </a>
                            <img src={!isMenuOpen ? MenuIC : CloseIC} className='menu-ic img-fluid' onClick={toggleMenu} />
                        </div>
                        <MenuList className='pt-2' isOpen={isMenuOpen} />
                    </div>
                </div>

            </Fragment>
        </section>
    </>);

    // return (
    //     <>
    //         <div className='container'>
    //             <nav className={"navbar navbar-expand-lg mt-1 mt-md-3 pt-3 pt-md-2" + " " + (config?.env === "UAT" ? "justify-content-between" : "")} aria-label="Eleventh navbar example">
    //                 {config?.env === "UAT" &&
    //                     <Fragment>
    //                         <a className='navbar-brand img-logo-box logo-images-boxes' href='/'>
    //                             <img src={Logo} className='img-fluid' />
    //                         </a>
    //                         <div className='signup-button-box'>
    //                             <Link className='btn-solid-sm dashboadrd-buttons-text' to='/signup'>{user?.access_token ? 'Dashboard' : 'Sign Up/Login'}</Link>
    //                             <Link className='btn-solid-sm mobile-buttons-text' to='/signup'>{user?.access_token ? 'Dashboard' : 'Sign up for Early Access '}</Link>
    //                         </div>
    //                     </Fragment>
    //                 }

    //                 {config?.env === "PROD" &&
    //                     <Fragment>
    //                         <a className='navbar-brand img-logo-box-prod' href='/'>
    //                             <img src={LogoProd} className='img-fluid' /></a>

    //                         <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
    //                             <span className="navbar-toggler-icon"></span>
    //                         </button>

    //                         <div className={'navbar-collapse' + ' ' + (isNavCollapsed ? 'collapse' : '')} id="navbarsExample09">
    //                             <ul className="navbar-nav me-auto mb-2 mb-lg-0">
    //                                 <li className="nav-item"></li>
    //                             </ul>
    //                             <div className='lender-button-box'>
    //                                 <Link className='btn-solid-wh' target="_blank" to={config?.b2bPortalLink}>Lenders</Link>
    //                             </div>

    //                             <div className='signup-button-box-prod'>
    //                                 {/* <Link className='btn-solid-sm' to='/signup'>Consumers</Link> */}
    //                                 <a className="btn-solid-sm-prod" href="#contact">Consumers</a>
    //                             </div>
    //                         </div>
    //                     </Fragment>
    //                 }
    //             </nav>
    //         </div>
    //     </>
    // );
}

export default Header;

