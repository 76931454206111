import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './document.css'
import '../../../App.css'
import Dropdown from 'react-dropdown';
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-dropdown/style.css';
import Loader from '../../loader/loader';
import { uploadGenDocument } from '../../../redux/actions/dashboard_action';
import showPwdImg from '../../../images/assets/show-password.png';
import hidePwdImg from '../../../images/assets/hide-password.png';
import UploadProgress from '../Component/Upload-Progress/upload-progress';

const Document = (props) => {

    const [selectedFiles, setSelectedFiles] = useState();
    const [selectedFileType, setSelectFileType] = useState();
    const [documentError, setDocumentError] = useState(false);
    const [loader, setLoader] = useState(false);
    const [score, setScore] = useState(-1);
    const [pwd, setPwd] = useState('');
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [showError, setShowError] = useState(false);
    const [referenceId, setReferenceId] = useState('')
    const [showProgressScreen, setShowProgressScreen] = useState(false)
    const [dialogHeight, setDialogHeight] = useState('auto')
    const [getDocType, setSetDocType] = useState('')
    const [completed, setCompleted] = useState(0);
    const inputRef = useRef();


    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setSelectedFiles(event.dataTransfer.files)
    };


    var t = [];
    var time1;
    var time2;
    var time3;
    var time4;
    var time5;
    var time6;
    var time7;
    var time8;


    const [documentType, setDocumentType] = useState([{
        label: 'Form 16',
        value: 'FORM_16'
    },
    {
        label: 'E-CAS Statement (CDSL)',
        value: 'CDSL'
    },
    {
        label: 'Form 26AS',
        value: 'FORM_26AS'
    },
    {
        label: 'E-CAS Statement (MF Central)',
        value: 'MF_CENTRAL'
    },
    {
        label: 'Credit Card Statement',
        value: 'CREDIT_CARD_STATEMENT'
    }
    ]);

    useEffect(() => {
        if (localStorage.getItem('messagesAI') !== null) {
            const storedObjectString = localStorage.getItem('messagesAI')
            const storedObject = storedObjectString ? JSON.parse(storedObjectString) : { key: 'value' };
            props.setMessageHistory(storedObject);
        }
    }, [])

    const onBureauChange = (value) => {
        setSelectFileType(value.value)
        setSetDocType(value.label)
    }

    const onFileSelect = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFiles(event.target.files)
        }
    }

    const clearTime = () => {
        clearTimeout(time1)
        clearTimeout(time2)
        clearTimeout(time3)
        clearTimeout(time4)
        clearTimeout(time5)
        clearTimeout(time6)
        clearTimeout(time7)
        clearTimeout(time8)
    }

    const submitDocument = () => {
        setShowError(false)
        setDocumentError(false)
        clearTime()
        if (!selectedFileType || selectedFileType === '' || !selectedFiles || selectedFiles === '') {
            clearTime()
            setShowError(true)
            setLoader(false);
        } else {
            // props.setOpenDocumentDialog(false) 
            //props.closeDialog()   
            //props.setShowProgressScreen(true)
            setShowProgressScreen(true)
            time1 = setTimeout(() => { setCompleted(5) }, 4500)
            var param = new FormData();
            param.append("file", selectedFiles[0]);
            param.append("fileType", selectedFileType);
            param.append("password", pwd);
            time2 = setTimeout(() => { setCompleted(15) }, 8500)
            const uuid = uuidv4();
            time3 = setTimeout(() => { setCompleted(40) }, 16000)
            time4 = setTimeout(() => { setCompleted(60) }, 18000)
            time5 = setTimeout(() => { setCompleted(75) }, 25000)
            time6 = setTimeout(() => { setCompleted(85) }, 30000)
            time7 = setTimeout(() => { setCompleted(90) }, 32000)
            time8 = setTimeout(() => { setCompleted(95) }, 35000)
            uploadGenDocument(param, uuid).then(resp => {
                // setDialogTitle('Upload Document')
                // setDialogHeight('350px')
                setSelectFileType(undefined);
                setSelectedFiles(undefined);
                setCompleted(99)
            }).catch((error) => {
                clearTime()
                // props.setOpenDocumentDialog(true)
                //props.setShowProgressScreen(false)
                setShowProgressScreen(false)
                setDocumentError(error?.errorMessage)
                setReferenceId(uuid)
            })
        }
    }

    return (
        <>
            {loader && <Loader />}
            <div className='container'>
                {!showProgressScreen && <form className="row g-2 needs-validation" noValidate>
                    <div className="col-12">
                        <label htmlFor="validationCustom04" className="label-label mb-2">Document Type</label>
                        <Dropdown options={documentType} onChange={(value) => {
                            onBureauChange(value)
                        }} value={selectedFileType} placeholder="Select document type" />
                        {(showError && (!selectedFileType || selectedFileType === '')) && <span className="demo-tetx">Please select valid document type</span>}
                    </div>
                    <div className="col-12 my-3">
                        {/* <label className="label-label mb-2">Select File</label>
                        <input type='file' className='form-control input-box' onChange={(event) => { onFileSelect(event) }} />
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please select valid document</span>}
                         */}
                        <label className="label-label mb-2">Select File</label>
                        <div
                            className="dropzone"
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                        >

                            <i className="bi bi-cloud-upload m-2"></i>

                            <input
                                type="file"
                                onChange={(event) => { onFileSelect(event) }}
                                hidden
                                ref={inputRef}
                            />
                            <div className='d-flex'><a className="choosefile" onClick={() => inputRef.current.click()}>choose a file</a><span className='ml-2 drag-txt'> or drag it here</span></div>

                            {selectedFiles &&
                                <div className="uploads">
                                    {Array.from(selectedFiles).map((selectedFiles, idx) => <span className='drag_file_name' key={idx}>{selectedFiles.name}</span>)}
                                </div>
                            }
                        </div>
                        {(showError && (!selectedFiles || selectedFiles === '')) && <span className="demo-tetx">Please select valid document</span>}

                    </div>


                    <div className='col-12'>
                        <label className='label-label mb-2'>File Password</label>
                        <div className='password-box'>
                            <input
                                name="pwd"
                                placeholder="Enter File Password (optional)"
                                type={isRevealPwd ? "text" : "password"}
                                value={pwd}
                                onChange={e => setPwd(e.target.value)}
                                className='form-control input-box'
                            />
                            <img className='password-icon'
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? hidePwdImg : showPwdImg}
                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                            />
                        </div>
                    </div>
                    <div>
                        <p className='demo-tetx'>{documentError === undefined ? '' : documentError}</p>
                        {referenceId !== '' && <p className='reference-txt pt-2'>Reference Id : {referenceId}</p>}
                    </div>
                    <div className="col-12 text-center mt-3">
                        <button className="upload-statement-butoon" type="button" onClick={() => {
                            submitDocument()
                        }} >Upload Document</button>
                    </div>
                </form>}

                {showProgressScreen && (
                    <div className='upload-progress'>
                        <ReactDialogBox
                            headerBackgroundColor='white'
                            headerTextColor='white'
                            headerHeight='0'
                            closeButtonColor='white'
                            bodyBackgroundColor='white'
                            bodyTextColor='black'
                            bodyHeight="400px"
                            borderRadius='8px'>
                            <UploadProgress closeDialog={props.closeDialog} docProgress={completed} docType={getDocType} setShowProgressScreen={setShowProgressScreen} />

                        </ReactDialogBox></div>)}

            </div>
        </>
    )
}

export default Document;