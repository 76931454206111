import { useEffect } from 'react';
import '../../../App.css';

const PrivacyPolicy = (props) => {

    useEffect(() => {
        if (window.location.href.indexOf("#google-api-disclosure") > -1) {
            var elem = document.getElementById("google-api-disclosure");
            elem.scrollIntoView();
        }
    }, [])



    return (
        <>

            <div className="container">

                <h2 className={props.openFromBM ? "mt-4 mb-3" : "mt-2 mb-3"}>Privacy Policy</h2>

                <p>FinBoost Software Private Limited (hereinafter “Boost.Money”) provides
                    cloud software and applications along with relevant API and SDK as a product for
                    businesses and individuals including associated offline and mobile applications
                    ("Service" or "Services"). We work with various businesses and Individuals
                    (“Customer or Customers”) to provide Services. Our Customers trust in
                    our platform and capabilities is of utmost importance to us and we go
                    above and beyond to ensure that the rights of our customers are protected
                    at all times.</p>

                <p>We are committed to maintaining the confidentiality, integrity and security
                    of any personal information about our Customers. We take our Customers
                    privacy seriously, our privacy guidelines underlines our commitment to
                    withholding our Customers privacy at all times.</p>

                <p><b>Customer Information :</b> Any data or information shared by our customers
                    is solely for the reason for providing product services to them. We enter
                    specific agreements with our customers to detail out the services provided
                    as well as handling and management of Customer Information. Any
                    Customer information is always managed with industry level encryption
                    best practices and data residency requirements are always managed
                    based on our Customer’s preferences.</p>

                <p><b>Information Use:</b> The usage of information and data to provide relevant
                    services is always outlined during onboarding solely as a means to
                    provide Services. The utilization and disclosure of information is solely
                    based on our Customer’s preferences as and when they consume our
                    analytics reports and product services output in a secure manner from our
                    platform. Platform level configurations and APIs are available for our
                    Customers to consume services based on their preferences.</p>

                <section id="google-api-disclosure">
                    <p><b>Google user Data:</b> (Boost.Money)use and transfer of information received from Google APIs to any other app will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes' target='_blank' className='googleLink'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

                    <p>We are in the business of providing credit and financial
                        wellness applications to our customers. Our application will only access
                        the specific data consented by customer during the signup process.
                        Explicit consent will be taken from customer during signup process to
                        access relevant and specific data from customer’s email for a specific
                        service like analytics service to provide credit improvement.</p>
                </section>

                <p>Such data will be always stored with industry best encryption practices,
                    based on customer’s consent as well as the customer would always have
                    data access and management rights.</p>

                <p>The data is never shared with anyone without explicit consent from the
                    customer. We are in the business of providing analytics services on top of
                    the data to our customers.</p>

                <p className="mb-5"><b>Disclosure:</b> We do not disclose any information to any third party except if
                    required to comply with the local law and/or regulator of a particular
                    country.</p>

            </div>

        </>
    )
}

export default PrivacyPolicy