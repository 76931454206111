import React from 'react'

const ExperianLogo = require("../../../images/experian_logo.png");

const ExpLogoFooter = () => {
    return (
        <>
            <div className='col-md-12 col-sm-12 col-lg-12 text-center'>
                <p className='csp-text'>Credit Score partner  | <img src={ExperianLogo} className='img-bm-logo' alt='exp-logo' /></p>
            </div>
        </>
    )
}
export default ExpLogoFooter;