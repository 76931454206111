
import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MenuBar from "../../pages/Dashboard/Menubar/menubar.js";
import Sidebar from "../../pages/Dashboard/SideBar/sidebar.js";
import { useNavigate } from 'react-router-dom';
import ProfileHeaderBanner from "./profile_component/profile_header";
import getUserData from "../../Common/helper.js";
import ProfileDropdown from "./profile_component/profile_dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./profile.css";
import { Link } from "react-router-dom";

import ConsentRejectModal from "../Component/consent-reject/consent-revoke-modal.js";
import { fetchFetchExperian, revokeConsentApi } from "../../redux/actions/dashboard_action.js";
import Loader from "../loader/loader.js";

const Avatar_logo = require("../../images/avatar-profile.png");

const Profile = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [businessName, setBusinessName] = useState()
  const [userData, setUserData] = useState();
  const [activeTab, setActiveTab] = useState("personalDetails");
  const [consentRevokeDate, setConsentRevokeDate] = useState('');

  const tabs = [
    { id: "personalDetails", label: "Personal Details" },
    { id: "creditReport", label: "Credit Report Permissions" }
  ];
  const [showModalPayload, setShowModalPayload] = useState({
    showModel: false,
    currentContent: 'EXPERIAN',
    msgErrorSuccess: ''
  });


  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleOpenModal = () => setShowModalPayload({
    showModel: true,
    currentContent: 'EXPERIAN', msgErrorSuccess: ''
  });
  const handleCloseModal = () => {
    setShowModalPayload({
      showModel: false,
      currentContent: 'EXPERIAN', msgErrorSuccess: ''
    })
  };



  useEffect(() => {

    const data = getUserData();
    setUserData(data);
    experianFetchConsent()
    // getProfileData()
    // .then((resp) => {
    //    if(resp.statusCode === "SUCCESS") {
    //     setFullName(resp.fullName)
    //     setEmail(resp.email)
    //     setBusinessName(resp.businessName)
    //    }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });       
  }, [])
  // Function to handle tab click

  const experianFetchConsent = () => {

    fetchFetchExperian().then((resp) => {
      if (resp.data != null) {
        if (resp.data.experianConsentStatus === 'ACTIVE') {
          const date = new Date(resp.data.consentExpiryTime);
          // Extract the day, month, and year
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
          const year = date.getFullYear();
          const formattedDate = `${day}-${month}-${year}`;
          setConsentRevokeDate(formattedDate)
        } else {
          setConsentRevokeDate('')
        }
      }
    }).catch((error) => {
      //  console.log(error);
    })
  }


  const ProfileDetail = ({ label, value, showDivider = true }) => (
    <>
      <div className="d-flex flex-row p-0 m-0 py-3">
        <div className="col-4">
          <p className="m-0 p-0 credit-bureau-text">{label}</p>
        </div>
        <div className="col-8">
          <p className="m-0 p-0 credit-bureau-text" style={{ fontWeight: '700' }}>{value ?? '-'}</p>
        </div>
      </div>
      {showDivider && <div className="dividers w-100 m-0 p-0"></div>}
    </>
  );


  const ConsentRevoke = () => {
    setLoader(true)
    revokeConsentApi().then((resp) => {
      if (resp?.data) {
        setShowModalPayload(
          {
            showModel: true,
            currentContent: resp?.data.statusCode === "SUCCESS" ? 'SUCCESS' : 'ERROR',
            msgErrorSuccess: resp?.data.statusMessage
          }
        );
      }

      setLoader(false);
    }).catch((error) => {
      setShowModalPayload(
        {
          showModel: true,
          currentContent: 'ERROR',
          msgErrorSuccess: error?.response.data.errorMessage
        }
      );
      setLoader(false);
    })
  }

  return (
    <>
      <MenuBar />
      <div className="sidebar-box sidnav-box">
        <Sidebar></Sidebar>
        <div>
          {loader && Loader}
        </div>

        <div className="sidebat-inner-box sidnav-inner-box">
          <div id="main_section" className="content ">
            <ProfileHeaderBanner name={userData?.name ?? ''} businessName={businessName}></ProfileHeaderBanner>
            <div className="p-0 m-0 d-flex flex-row ">
              {tabs.map((tab) => (
                <section
                  key={tab.id}
                  className="profile-sub-section me-3"
                  onClick={() => handleTabClick(tab.id)}
                >
                  <p className="profile-sub-section-label"
                    style={{ fontWeight: activeTab === tab.id ? '800' : '500' }}
                  >{tab.label}</p>
                  {activeTab === tab.id && <div className="profile-sub-section-divider"></div>}
                </section>
              ))}
            </div>
            {/* {activeTab === 'personalDetails' &&
              <section className="profile-text-form col-12 col-sm-12 col-md-6 col-lg-5">
                <p className="profile-text-field-label">First Name</p>
                <input
                  type="text" readOnly
                  value={userData?.given_name ?? ''}
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Last Name</p>
                <input
                  type="text" readOnly value={userData?.family_name ?? ''}
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Email</p>
                <input
                  type="text" readOnly
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Mobile Number</p>
                <input
                  type="text" readOnly value={userData?.preferred_username ?? ''}
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">PAN</p>
                <input
                  type="text" readOnly
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Passport</p>
                <input
                  type="text" readOnly
                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Date Of Birth</p>
                <input
                  type="text" readOnly

                  className="profile-text-field-layout"

                ></input>

                <p className="profile-text-field-label">Address</p>
                <textarea
                  rows="4" cols="50"
                  type="text"
                  className="profile-text-area-layout"
                ></textarea>


              </section>} */}

            {activeTab === 'personalDetails' &&
              <section className="profile-text-form">
                <div className='my-3'>
                  <div className='insights-main-box cursor-pointer'>
                    <div className='row g-2'>
                      <div className='col-12 p-0'>
                        <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Basic information</p>
                      </div>
                    </div>
                    <div className='dividers w-100'></div>
                    <div className='row g-2 ps-2 ps-lg-4 ps-md-3 m-0 pe-2 pe-lg-4 pe-md-3'>

                      <ProfileDetail label="First name" value={userData?.given_name} />
                      <ProfileDetail label="Last name" value={userData?.family_name} />
                      <ProfileDetail label="Date of birth" value={userData?.dob} showDivider={false} />
                    </div>
                  </div>
                </div>

                <div className='my-3'>
                  <div className='insights-main-box cursor-pointer'>
                    <div className='row g-2'>
                      <div className='col-12 p-0'>
                        <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Contact Information</p>
                      </div>
                    </div>
                    <div className='dividers w-100'></div>
                    <div className='row g-2 ps-2 ps-lg-4 ps-md-3 m-0 pe-2 pe-lg-4 pe-md-3'>
                      <ProfileDetail label="Email" value={userData?.email} />
                      <ProfileDetail label="Phone" value={userData?.preferred_username} showDivider={false} />
                    </div>
                  </div>
                </div>

                <div className='my-3'>
                  <div className='insights-main-box cursor-pointer'>
                    <div className='row g-2'>
                      <div className='col-12 p-0'>
                        <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Identification Information</p>
                      </div>
                    </div>
                    <div className='dividers w-100'></div>
                    <div className='row g-2 ps-2 ps-lg-4 ps-md-3 m-0 pe-2 pe-lg-4 pe-md-3'>
                      <ProfileDetail label="PAN" value={userData?.pan} />
                      <ProfileDetail label="Passport" value={userData?.passport} showDivider={false} />
                    </div>
                  </div>
                </div>

                <div className='my-3'>
                  <div className='insights-main-box cursor-pointer'>
                    <div className='row g-2'>
                      <div className='col-12 p-0'>
                        <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Identification Information</p>
                      </div>
                    </div>
                    <div className='dividers w-100'></div>
                    <div className='row g-2 ps-2 ps-lg-4 ps-md-3 m-0 pe-2 pe-lg-4 pe-md-3'>
                      <ProfileDetail label="Address" value={userData?.address} showDivider={false} />

                    </div>
                  </div>
                </div>

              </section>}

            {activeTab === 'creditReport' &&
              <section id='insights'>
                <div className='my-3'>
                  <div className='insights-main-box cursor-pointer'>
                    <div className='row g-2'>
                      <div className='col-12 p-0'>
                        <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Credit report Permission</p>
                      </div>
                    </div>
                    <div className='dividers w-100'></div>
                    <div className='row g-2 ps-2 ps-lg-4 ps-md-3 m-0'>

                      <div className="d-flex flex-row p-0 m-0 py-3">
                        <div className="col-4">
                          <p className="m-0 p-0 credit-bureau-text">Experian Credit Report</p>
                        </div>

                        <div className="col-8">
                          {consentRevokeDate !== '' && <p className="m-0 p-0 credit-bureau-text"
                            style={{ textTransform: 'lowercase' }}
                            onClick={() => {
                              setShowModalPayload(
                                {
                                  showModel: true,
                                  currentContent: 'EXPERIAN',
                                  msgErrorSuccess: ''
                                }
                              );
                            }}
                          ><a id="revoke_consent_profile" href="#" alt="revoke_consent" style={{ color: '#4243F1', fontWeight: '600', textTransform: 'capitalize',textDecoration: 'none' }}>
                              Revoke consent</a> Consent valid till {consentRevokeDate}</p>}

                          {consentRevokeDate === '' && <p className="m-0 p-0 credit-bureau-text"
                          >There is no consent available!</p>}

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section >
            }

            {showModalPayload.showModel &&
              <ConsentRejectModal onClickExpFetch={() => {
                ConsentRevoke()
              }} onClose={handleCloseModal}
                onCloseFail={() => {
                  navigate('/dashboard')
                }}
                onCloseSuccsess={() => {
                  navigate('/dashboard')
                }}
                msgErrorSuccess={showModalPayload.msgErrorSuccess}
                showTypeContent={showModalPayload.currentContent} />
            }

            <section className="space"></section>
          </div>
          {/* <Footer /> */}
        </div>
      </div>




    </>
  );
};
export default Profile;
