import { useLocation, useNavigate, } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const JobIC = require("../../../images/assets/job-ic.png");
const ScoreIC = require("../../../images/assets/score.png");
const SubmitIC = require("../../../images/assets/submit-ic.png");
const MailIC = require("../../../images/assets/mail_ic.png");
const RedirectIC = require("../../../images/assets/redirect_ic.png");
const ExperianLogo = require("../../../images/experian_logo.png");

const DisputeCreditComponent = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        } 
    }, []);   

    const openDisputeForm = () => {
        window.open('https://consumer.experian.in/ECSINDIA-DCE', '_blank');
    };
    return (
        <div className='mb-4'>
            <div className='container-lg container-fluid my-md-4'>
                <div className='bank-state-box m-0 pt-5 pb-3'>
                    <div className='row'>
                        <div className='co-12 text-center'>
                            <h1 className='boosted-score-text'>Raise a dispute on your credit report</h1>
                            <p className='boosted-moneytetx'>Visit Experian dispute form, fill in the form then submit after thorough checking</p>
                        </div>
                    </div>
                    <div className='row mt-3 g-2 g-sm-4 text-center'>
                        <div className='col-sm-12 col-lg-4 px-4'>
                            <div className='bankbox-card'>
                                <div className='bankbox-inner-card g-2'>
                                    <h4 className='numbers'>01.</h4>
                                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                        <img src={ScoreIC} className='img-fluid' alt="Score Icon" />
                                    </div>
                                    <p className='get-score-images-text text-break '>Fill in your details for latest report</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                            <div className='bankbox-card'>
                                <div className='bankbox-inner-card g-2'>
                                    <h4 className='numbers'>02.</h4>
                                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                        <img src={JobIC} className='img-fluid' alt="Job Icon" />
                                    </div>
                                    <p className='get-score-images-text text-break'>Edit or change the inaccuracy</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                            <div className='bankbox-card'>
                                <div className='bankbox-inner-card g-2'>
                                    <h4 className='numbers'>03.</h4>
                                    <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                        <img src={SubmitIC} className='img-fluid' alt="Submit Icon" />
                                    </div>
                                    <p className='get-score-images-text text-break'>Simply raise your dispute</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3 g-3'>
                        <div className='col-12 text-center my-3'>
                            <button className='mb-0 upload-statement-btn' onClick={() => {
                                openDisputeForm();
                            }}>
                                Experian Dispute Form <span><img src={RedirectIC} alt="ic-redirect" className="mail-ic ms-1" /></span></button>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                        <div className='hr-line w-50'></div>
                        <p className='m-0 p-0 ps-3 pe-3'>or</p>
                        <div className='hr-line w-50'></div>
                    </div>
                    <div className='header-enable mt-4'>
                        <div className='col-12 text-center my-3'>
                            <p className='desc-line-2'>You can also write to the Consumer Support team for Dispute Resolution</p>
                            <div className='mt-4'>
                                <img src={MailIC} alt='mail-ic' className='mail-ic me-2' />
                                <a id="mailto_customer_support_experian" href="mailto:Consumer.Support@in.experian.com" style={
                                    { 'color': '#4243F1' }
                                }>
                                    Consumer.Support@in.experian.com
                                </a>
                            </div>
                            <div className='mt-5'>
                                <p className='dsp-text'>Dispute powered by  | <img src={ExperianLogo} className='img-bm-logo' alt='exp-logo' /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DisputeCreditComponent;
