import React from 'react';
import { useEffect } from 'react';
import '../../Chat/chatai.css'

const documentSuccess = require("../../../../images/assets/document-success.png");

function UploadSuccess(props) {


    useEffect(() => {
        //props.setShowProgressScreen(false)
    });

    const backToAI = () => {
        props.closeDialog()
    }

    return (
        <>
            <div className='col-12 text-center mt-4'>
                <img src={documentSuccess} alt='document-upload' />
            </div>

            <div className='col-12 text-center mt-2 upload-text-msg'>
                <h4>{props?.docType} uploaded successfully.</h4>
                {/* <h4>You can continue your chat while we analyze your document.</h4>

                <h4 className='mt-2'>This might take a minute.</h4> */}
            </div>

            <div className='col-12 text-center mt-5'>
                <button id="back_to_boost_ai" className='mb-0 upload-statement-btn wd-100 btn-sm-font' onClick={() => { backToAI() }}>Back to Boost AI</button>
            </div>
        </>
    );
}

export default UploadSuccess;