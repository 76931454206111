import React from 'react'

const BoostLogo = require("../../images/assets/Logo.png");

const Footer = () => {
    return (
        <>
            <div className='row justify-content-center'>
                <div className='text-center pt-4'>
                    <h6 className='power-by'>Powered by : <img src={BoostLogo} className='img-bm-logo' alt='' /></h6>
                </div>
            </div>
        </>
    )
}
export default Footer;