import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Footer from '../../progress/footer';
import '../boost-score-bank/boost-score-bank.css'
import './add-bank-account.css'
import '../../../App.css'
import 'react-js-dialog-box/dist/index.css'

const FiuSuccess = require("../../../images/assets/fiu_success.png");

const Fiusuccessscreen = (props) => {

    const [loader, setLoader] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/');
        }

    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const backToHome = () => {
        props.setFiuSuccessModel(false);
        props.setFipTspModel(false);
        props.setIsOpenDialog(false);
        props.getBoostScoreReport()
        props.scrollToTop();
    }

    return (
        <>
            <div className=' p-0 m-0 d-flex justify-content-center align-items-center'>
                <div className='p-0 m-0 my-md-4 fiu-response text-center tspErrorFiu'>
                    <div className='row p-0 m-0 text-center'>
                        <div className='col-12 text-center my-3 p-0 '>
                            <div className="fiu_success_text pt-0">Congratulations!</div>
                            <span className='no_ac_sub_txt'>{props.fiuMsg ?? 'You have successfully shared all your accounts.'}</span>
                        </div>
                        <div className='pt-20'></div>
                        <div className='col-12 text-center my-3'>
                            <img src={FiuSuccess} alt='fiu_error' className='img-fluid fiu-success-img' />
                        </div>
                        <div className='pt-4'></div>
                        <div className='col-12 text-center my-3 pt-20 mb-0'>
                            <button className='mb-0 upload-statement-btn' onClick={() => { backToHome() }}>Back to home</button>
                        </div>

                        <Footer />
                        <div className='mt-1'></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fiusuccessscreen;