import React, { useState, useEffect, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid';
import '../boost-score-bank/boost-score-bank.css'
import './add-bank-account.css'
import '../../../App.css'
import Fiuerrorscreen from '../add-bank-account/fiu-error-screen'
import Fiusuccessscreen from '../add-bank-account/fiu-success-screen'
import BankStatmentForm from '../bank-statement/bank-statement'
import Loader from '../../loader/loader';
import { getFipTSPUrl, getFiuStatus, boostScoreReport } from '../../../redux/actions/dashboard_action'
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'
import Progress from '../../progress/progress';
const AddBank = require("../../../images/assets/AddBank.png");
const GetInsights = require("../../../images/assets/Getinsights.png");
const Finances = require("../../../images/assets/Finances.png");

const Addbankaccount = (props) => {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Upload Bank Statement')
    const [dialogHeight, setDialogHeight] = useState('auto');
    const [fipTspDialog, setFipTspModel] = useState(false);
    const [fiuerrorModel, setFiuErrorModel] = useState(false);
    const [fiusuccessModel, setFiuSuccessModel] = useState(false);
    const [fipTSPData, setFipTSPData] = useState(false);
    const [getUrl, setUrl] = useState();
    const [score, setScore] = useState(0);
    const [loader, setLoader] = useState(false);
    const [fiuRequestId, setFiuRequestId] = useState();
    const [tspLoader, setTspLoader] = useState(false);
    const [classOnLoadAA, setClassOnLoadAA] = useState("");
    const [postMessageCall, setPostMessageCall] = useState(false)
    const [newReferenceId, setNewReferenceId] = useState();
    const [isOpenDialogBank, setIsOpenDialogBank] = useState(false);
    // const [dialogTitle, setDialogTitle] = useState('Upload Bank Statement')
    // const [dialogHeight, setDialogHeight] = useState('auto');

    const [getStatus, setStatus] = useState("")
    const [fiuMsg, setfiuMsg] = useState("")
    let fipRequestCheck;
    var referenceId;
    let bankNameList = [];

    useEffect(() => {

        window.addEventListener('message', fetchIframeData, false);
        function fetchIframeData(e) {
            try {
                e.preventDefault()
                const iframeData = e.data && e.data.status ? e.data : null;
                if (iframeData) {
                    const status = iframeData.status;
                    const responseFrom = iframeData.responseFrom;
                    referenceId = iframeData.referenceId;
                    if ((status === 'SUCCESS' || status === 'success') && responseFrom === "FIUTSP") {
                        setFipTspModel(false);
                        setStatus("");
                        setTspLoader(true);
                        fipRequestCheck = setInterval(() => {
                            fetchFIURequestStatus(referenceId);
                        }, 6000);
                        setPostMessageCall(true)

                    } else if ((status === 'ERROR' || status === 'error' || status === "CONSENT_REJECTED") && responseFrom === "FIUTSP") {
                        if (status === "CONSENT_REJECTED") {
                            setfiuMsg("You have rejected the consent required for Account Aggregator.")
                        }
                        setFiuErrorModel(true);
                        setIsOpenDialog(true)
                        setFipTspModel(false);
                        setFiuSuccessModel(false);
                        setTspLoader(false);
                        setPostMessageCall(true)

                    } else if (status === 'AAIframe' && responseFrom === "FIUTSP") {
                        setClassOnLoadAA('disabledCloseBtn');
                        setPostMessageCall(true)
                    } else if (status === 'AAError' && responseFrom === "FIUTSP") {
                        setClassOnLoadAA("");
                        setPostMessageCall(true)
                    }
                }

            } catch (e) {

                console.log(e);
            }
        }

    }, []);

    const fetchFIURequestStatus = (referenceId) => {
        if (referenceId && referenceId != "") {
            var param = {
                "referenceId": referenceId
            }
            getFiuStatus(param, referenceId).then(resp => {
                if (resp.data && resp.data.statusCode === "SUCCESS") {

                    if (resp.data.status === "SCORE_GENERATED" || resp.data.status === "SCORE_NOT_GENERATED" || resp.data.status === "GST_DATA_FETCHED") {

                        clearInterval(fipRequestCheck);
                        setStatus(resp.data.status);

                        setfiuMsg(resp.data.statusMessage)

                        executeAfterScoreGenerate();

                    } else if (resp.data.status === "CONSENT_REQUEST_FAILED"
                        || resp.data.status === "CONSENT_REJECTED"
                        || resp.data.status === "CONSENT_APPROVAL_FAILED"
                        || resp.data.status === "CONSENT_ARTIFACT_FAILED"
                        || resp.data.status === "FI_REQUEST_FAILED"
                        || resp.data.status === "FI_TIME_OUT"
                        || resp.data.status === "FI_FETCH_FAILED"
                        || resp.data.status === "CATEGORISATION_ERROR"
                        || resp.data.status === "ANALYSIS_ERROR"
                        || resp.data.status === "TRANSACTION_DATA_NOT_AVAILABLE"
                        || resp.data.status === "FIU_REQUEST_ID_NOT_FOUND"
                        || resp.data.status === "FI_DATA_FETCH_FAILED"

                    ) {

                        clearInterval(fipRequestCheck);
                        setStatus(resp.data.status);
                        setfiuMsg(resp.data.statusMessage)
                        setNewReferenceId(referenceId)
                        setLoader(false);
                        setFiuErrorModel(true);
                        setIsOpenDialog(true)
                        setFipTspModel(false);
                        setFiuSuccessModel(false);
                        setTspLoader(false);
                        props.scrollToTop()
                    } else {
                        setStatus(resp.data.status);
                    }
                } else {

                    clearInterval(fipRequestCheck);
                }
            }).catch(error => {
                clearInterval(fipRequestCheck);
                props.setMessage({ error: error });
            })
        }
    }

    // const getBoostScoreReport = () => {
    //     boostScoreReport().then((resp) => {
    //         setScore(resp["score"])
    //         props.setScore(resp["score"])
    //         props.setMessage({ success: "Your Score is generated" })
    //         // console.log(resp)
    //         let bankListNew = getBankList(resp.accounts)
    //        // let index = bankListNew.length > 0 ? bankListNew.length -1 : 0;
    //         let index = 0;
    //         //props.setBankList(bankListNew)
    //         let resAccount = resp.accounts[index];
    //         console.log(resAccount?.analysis?.accountDetail ? resAccount?.analysis.accountDetail.bankName +" Bank | "+ resAccount?.analysis.accountDetail.accountType +' Account - '+ resAccount?.analysis.accountDetail.accountNumber.slice(-4) : null)
    //         props.setNewBankname(resAccount?.analysis?.accountDetail ? resAccount?.analysis.accountDetail.bankName +" Bank | "+ resAccount?.analysis.accountDetail.accountType +' Account - '+ resAccount?.analysis.accountDetail.accountNumber.slice(-4) : null)
    //         props.setAcHolderName(resAccount?.analysis?.accountDetail ? resAccount.analysis.accountDetail.accountHolderName : null)
    //         props.setTransactionPeriod(resAccount?.analysis?.accountDetail ? resAccount.analysis.accountDetail.transactionStartDate +' - '+ resAccount.analysis.accountDetail.transactionEndDate : null)
    //         props.setAnalysisReportData(resAccount?.analysis ? resAccount.analysis :null)
    //         props.setIncomeVsExpenseData(resAccount?.analysis?.incomeVsExpenseAnalysis ? resAccount.analysis.incomeVsExpenseAnalysis : null)
    //         props.setTopSpendingCategoryData(resAccount?.analysis?.categoryAnalysis ? resAccount.analysis.categoryAnalysis : null)
    //         props.setTopMerchantData(resAccount?.analysis?.merchantAnalysis ? resAccount.analysis.merchantAnalysis : null)
    //         props.setTopCardUtilizationData(resAccount?.analysis?.creditCardUtilization ? resAccount.analysis.creditCardUtilization : null)
    //         props.setMonthlyObligationData(resAccount?.analysis?.obligation ? resAccount.analysis.obligation : null)
    //         props.setInvestmentData(resAccount?.analysis?.investment ? resAccount.analysis.investment : null)
    //         props.setMessage({ success: resAccount.statusMessage })
    //         window.scrollTo({
    //             top: 0,
    //             behavior: "smooth",
    //         });
    //     }).catch((error) => {
    //         console.log(error);
    //     })
    // }

    // const getBankList = (bankLists) => {
    //     if(bankLists){
    //       for (let index = 0; index < bankLists.length; index++) {
    //            bankNameList.push(bankLists[index].analysis.accountDetail.bankName +" Bank | "+ bankLists[index].analysis.accountDetail.accountType +' Account - '+ bankLists[index].analysis.accountDetail.accountNumber.slice(-4));
    //       }
    //     }
    //    return bankLists;
    // }

    const openDialog = () => {
        setFiuSuccessModel(false)
        setFiuErrorModel(false)
        setIsOpenDialog(true)
        addBankAccount()
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setFiuSuccessModel(false);
        setDialogTitle('Upload Bank Statement')
        setDialogHeight('auto')
       
        props.scrollToTop()
    }

    const closeTspPopup = () => {
        setFipTspModel(false);
        setFiuSuccessModel(false)
        setFiuErrorModel(false)
        setIsOpenDialog(false)
    }

    const FipTspModel = (props) => {
        return <div className='tsp-iframe-popup text-center'>
            <span id="close-icon" className={classOnLoadAA ? 'bi bi-x tsp-close-btn ' + classOnLoadAA : 'bi bi-x tsp-close-btn'} onClick={() => {
                closeTspPopup()
            }}></span>
            <iframe
                title={props.src}
                allowFullScreen
                frameBorder="0"
                height="600px" //changed by MR  before 550px
                src={props.src}
                width="100%"  //changed by MR
                id="tspiframe"
                sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
            />
        </div>
    }

    async function executeAfterScoreGenerate() {
        // Wait for 5000 milliseconds
        new Promise(resolve => setTimeout(resolve, 2000))
            .then(() => {
                // After 3000 milliseconds, execute the following code
                setNewReferenceId(referenceId);
                setFiuSuccessModel(true);
                setIsOpenDialog(true);
                setFipTspModel(false);
                setTspLoader(false);

            })
            .catch(error => {
                // Handle any errors that occurred during the 2000 milliseconds delay
                console.error("Error occurred during delay:", error);
            });

    }

    const TspLoader = () => {
        return <div className="text-center" id="loading">
            <div className="row justify-content-center mtp-50">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                <div className="row text-center justify-content-center">
                    <div className="loader-txt mt-20">Please wait...</div>
                    <div className="loader-txt">Aggregating and Analyzing Transactions</div>
                </div>
            </div>
        </div>
    }

    const addBankAccount = () => {
        setFipTspModel(true);
        setLoader(true);
        const uuid = uuidv4();
        getFipTSPUrl(uuid)
            .then(resp => {
                if (resp.data.statusCode === "SUCCESS") {
                    setLoader(false)
                    let fiuUrl = resp.data.url;
                    let urlQuery = fiuUrl.split('?');
                    setUrl(resp.data.url + "&b2b=false");
                    //setUrl("http://localhost/fiu-tsp-web/?" + urlQuery[1] + "&b2b=false");
                    setFiuRequestId(resp.data.referenceId);
                    setFipTSPData(resp)
                }
            }).catch(error => {
                clearInterval(fipRequestCheck);
                console.log(error)
            })
    }

    const openDialogBank = () => {
        setIsOpenDialogBank(true)
        setDialogTitle('Upload Bank Statement')
        setDialogHeight('auto')
    }

    const closeDialogBank = () => {
        setIsOpenDialogBank(false)
        setDialogHeight('auto')
        props.scrollToTop()
    }

    const callbackFromConsentReject = (data) => {
        if (data == "REJECT") {
            setStatus('');
            setFiuErrorModel(true);
            setFipTspModel(false);
            setFiuSuccessModel(false);
            setTspLoader(false);
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className='mb-4 fiu-box'>
                <div className='container-lg container-fluid my-md-4'>
                    <div className='bank-state-box'>
                        <div className='row'>
                            <div className='co-12 text-center '>
                                <h1 className='boosted-score-text text-uppercase'>Get A boosted credit score</h1>
                                <p className='boosted-moneytetx'>Share the bank statement and  get insights and a boosted score</p>
                            </div>
                        </div>

                        <div className='row mt-3 g-2 g-sm-4 text-center'>
                            <div className='col-sm-12 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>01.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={AddBank} className='img-info' />
                                        </div>
                                        <p className='get-score-images-text text-break '>Add your bank account(s)</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>02.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={GetInsights} className='img-info' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Get insights and recommendations</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-4'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>03.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={Finances} className='img-info' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Boost your credit score and finances</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 g-3'>

                            <div className='col-12 text-center my-3 fiupopup'>
                                <button id="add_bank_account_open_sdk" className='mb-0 upload-statement-btn' onClick={() => { openDialog() }}>Add Bank Account</button>
                            </div>

                            {isOpenDialog && (
                                <ReactDialogBox
                                    headerBackgroundColor='white'
                                    headerTextColor='black'
                                    closeButtonColor='black'
                                    bodyBackgroundColor='white'
                                    bodyTextColor='black'
                                    bodyHeight={dialogHeight}
                                    borderRadius='8px'
                                    headerHeight='65px'
                                >

                                    {fipTspDialog ?
                                        <FipTspModel src={getUrl} />
                                        : null
                                    }

                                    {/* {tspLoader ?
                                        <TspLoader></TspLoader>
                                        : null
                                    } */}
                                    {tspLoader ?
                                        <Progress
                                            callbackFromConsentReject={callbackFromConsentReject}
                                            fiustatus={getStatus}

                                            /* Client Configuration */
                                            startFlow={null}
                                            creditInformation={null}
                                        />
                                        : null
                                    }
                                    <div className='fiu-error'>
                                        {fiuerrorModel ?

                                            <Fiuerrorscreen
                                                fiuMsg={fiuMsg}
                                                closeDialog={closeDialog}
                                                setScore={props.setScore}
                                                setDialogTitle={setDialogTitle}
                                                setDialogHeight={setDialogHeight}
                                                setFipTspModel={setFipTspModel}
                                                setIsOpenDialog={setIsOpenDialog}
                                                setFiuErrorModel={setFiuErrorModel}
                                                addBankAccount={addBankAccount}
                                                fipRequestCheck={fipRequestCheck}
                                                newReferenceId={newReferenceId}
                                                // setMessage={props.setMessage}
                                                // scrollToTop={props.scrollToTop}
                                                // setAnalysisReportData={props.setAnalysisReportData}
                                                // setIncomeVsExpenseData={props.setIncomeVsExpenseData}
                                                // setTopSpendingCategoryData={props.setTopSpendingCategoryData}
                                                // setTopMerchantData={props.setTopMerchantData}
                                                // setTopCardUtilizationData={props.setTopCardUtilizationData}
                                                // setMonthlyObligationData={props.setMonthlyObligationData}
                                                // setInvestmentData={props.setInvestmentData}
                                                getBoostScoreReport={props.getBoostScoreReport}

                                            /> : null}
                                    </div>


                                    {fiusuccessModel ?
                                        <Fiusuccessscreen
                                            fiuMsg={fiuMsg}
                                            closeDialog={closeDialog}
                                            setDialogTitle={setDialogTitle}
                                            setDialogHeight={setDialogHeight}
                                            setFiuSuccessModel={setFiuSuccessModel}
                                            setFipTspModel={setFipTspModel}
                                            setIsOpenDialog={setIsOpenDialog}
                                            scrollToTop={props.scrollToTop}
                                            newReferenceId={newReferenceId}
                                            getBoostScoreReport={props.getBoostScoreReport}
                                        /> : null}
                                </ReactDialogBox>)}

                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <div className='hr-line w-50'></div>
                                <p className='m-0 p-0 ps-3 pe-3'>or</p>
                                <div className='hr-line w-50'></div>
                            </div>


                            <div className='header-enable'>
                                <div className='col-12 text-center my-3'>
                                    <button id="upload_bank_statement_dialog_open" className='mb-0 upload-statement-btn upload-statement-btn-white' onClick={() => { openDialogBank() }}>Upload Bank Statement</button>
                                </div>

                                {isOpenDialogBank && (
                                    <ReactDialogBox
                                        closeBox={closeDialogBank}
                                        headerBackgroundColor='white'
                                        headerTextColor='black'
                                        headerHeight='65px'
                                        closeButtonColor='black'
                                        bodyBackgroundColor='white'
                                        bodyTextColor='black'
                                        bodyHeight={dialogHeight}
                                        headerText={dialogTitle}
                                        borderRadius='8px'
                                    >
                                        <BankStatmentForm
                                            closeDialog={closeDialogBank}
                                            setIsOpenDialog={setIsOpenDialogBank}
                                            // setScore={props.setScore}
                                            setDialogTitle={setDialogTitle}
                                            setDialogHeight={setDialogHeight}
                                            getBoostScoreReport={props.getBoostScoreReport}
                                            
                                            // setMessage={props.setMessage}
                                            // setAnalysisReportData={props.setAnalysisReportData}
                                            // setIncomeVsExpenseData={props.setIncomeVsExpenseData}
                                            // setTopSpendingCategoryData={props.setTopSpendingCategoryData}
                                            // setTopMerchantData={props.setTopMerchantData}
                                            // setTopCardUtilizationData={props.setTopCardUtilizationData}
                                            // setMonthlyObligationData={props.setMonthlyObligationData}
                                            // setInvestmentData={props.setInvestmentData}
                                            // setNewBankname={props.setNewBankname}
                                            // setAcHolderName={props.setAcHolderName}
                                            // setTransactionPeriod={props.setTransactionPeriod}
                                        />
                                    </ReactDialogBox>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Addbankaccount;