
import "../experian_term_condition/experian_term.css";
import ExperianTerms from '../../landing-page/Component/experian-term';
import Header from '../../landing-page/header/header';
import Footer from '../../landing-page/Footer/Footer';

function ExperianTerm(props) {

  // const closeExperianTerms = () => {
  //   props.setExperianTerms(false)
  // }

  return (
    <>
      <Header></Header>
      <ExperianTerms openFromBM={true}></ExperianTerms>
      <Footer></Footer>
    </>
  );
}
export default ExperianTerm;
