// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._Xt-75 {
    z-index: 9991 !important;
    left: 55% !important;
    max-width: 410px !important;
}

._3qw0K {
    z-index: 9991 !important;
    border-radius: 10px;
}

._br_-q {
    align-items: center !important;
    border-radius: 10px 10px 0px 0px;
}

._2MJ9D{
    border-radius: 0px 0px 10px 10px;
}

.Dropdown-control {
    border: 1px solid #D1D1D1 !important;
    border-radius: 5px !important;
}

#fiuTspModal {
    height: auto !important;
    width: 85% !important;
}

@media (max-width:767px) {
    ._Xt-75 {
        max-width: 80% !important;
        margin: auto !important;
    }

    #fiuTspModal {
        width: 65% !important;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/boost-score/boost-score-bank/boost-score-bank.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,oBAAoB;IACpB,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,8BAA8B;IAC9B,gCAAgC;AACpC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI;QACI,yBAAyB;QACzB,uBAAuB;IAC3B;;IAEA;QACI,qBAAqB;IACzB;;AAEJ","sourcesContent":["._Xt-75 {\n    z-index: 9991 !important;\n    left: 55% !important;\n    max-width: 410px !important;\n}\n\n._3qw0K {\n    z-index: 9991 !important;\n    border-radius: 10px;\n}\n\n._br_-q {\n    align-items: center !important;\n    border-radius: 10px 10px 0px 0px;\n}\n\n._2MJ9D{\n    border-radius: 0px 0px 10px 10px;\n}\n\n.Dropdown-control {\n    border: 1px solid #D1D1D1 !important;\n    border-radius: 5px !important;\n}\n\n#fiuTspModal {\n    height: auto !important;\n    width: 85% !important;\n}\n\n@media (max-width:767px) {\n    ._Xt-75 {\n        max-width: 80% !important;\n        margin: auto !important;\n    }\n\n    #fiuTspModal {\n        width: 65% !important;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
