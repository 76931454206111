import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MiddleContentCD from '../Component/middle-cd';
import getUserData from '../../../Common/helper';
import '../boost-ai.css'

const BMLOGO = require("../../../images/assets/ai-profile.png");
const AILOGOGIF = require("../../../images/boost-ai.gif");

const INFO_IC = require("../../../images/assets/info.png");
const CHAT_IC = require("../../../images/assets/msg_ic.png");
const PRIVACY_IC = require("../../../images/assets/privacy.png");

function AIInfo(props) {

    const navigate = useNavigate();
    const [userData, setUserData] = useState();

    const [showChatAIBox, setShowChatAIBox] = useState(false)
    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')

    useEffect(() => {
        if (!user?.access_token) {
            navigate('/')
        }
        const data = getUserData()
        setUserData(data)
    }, [])

    const { user } = useSelector(state => {
        return state.authReducer;
    });

    const openChatGenAI = () => {
        localStorage.removeItem('messagesAI');
        props.setShowChatAIInfoBox(true)
        props.setShowChatIcon(false)
        props.setShowAIInfoBox(false)
        props.setShowGenAI(true)

        // setDialogTitle('BOOST AI')
        // setDialogHeight('auto')
    }

    return (
        <>
            <div className='container mt-2'>
                <section className="middle-content mb-10">
                    <div className="profile-main text-center pt-1">
                        <img src={AILOGOGIF} className='chat-ai-logo' />
                        <h5 className='chat-profile-name mt-3'>Hi {userData?.given_name}!</h5>
                        <p className="chat-sub-line">Welcome to Boost.Money</p>
                    </div>

                    <div className="container bg-content-center mt-2">
                        <p className="tips-txt pt-3">Tips for getting started</p>
                        <div className='hr-white-line'></div>
                        <div className="col-12 d-flex flex-wrap pt-4 ps-4 pe-4 pb-3 justify-content-between">
                            <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={CHAT_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Ask Boost AI</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">BOOST AI can answer your questions related to your financial data like Bank transactions, Tax  and Credit Report.</p>
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={PRIVACY_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Sensitive data</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">Your conversations are processed by human reviewers to improve Boost AI. Don’t enter anything you wouldn’t want reviewed or used.</p>
                            </div>

                            <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                                <div className="img-txt">
                                    <img src={INFO_IC} alt="sub-icon" className="sub-icon" />
                                    <p className="sub-title">Check your facts</p>
                                </div>
                                <p className="sub-desc pt-2 ps-1">We have safeguards, but sometimes Boost AI can make mistakes.</p>
                            </div>

                        </div>
                        <div className="py-4">
                            <button onClick={() => { openChatGenAI() }} id="callApiOpenSdk" className="upload-statement-btn wd-100">Let’s get started</button>
                        </div>
                    </div>
                    {/* <MiddleContentCD setShowChatAIInfoBox={props.setShowChatAIInfoBox} setShowChatIcon={props.setShowChatIcon} closeInfoDialog={props.closeInfoDialog}> </MiddleContentCD> */}
                </section >
            </div>
        </>
    );
}

export default AIInfo;