import '../../../App.css';
import './bankstatement.css';
import { ReactDialogBox } from 'react-js-dialog-box'
import 'react-js-dialog-box/dist/index.css'
import { useState } from 'react';
import CreditReport from '../credit-report/credit-report';

const AddBank = require("../../../images/assets/AddBank.png");
const GetInsights = require("../../../images/assets/Getinsights.png");
const Finances = require("../../../images/assets/Finances.png");

function BankStatment(props) {
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('Upload Credit Report')
    const [dialogHeight, setDialogHeight] = useState('auto')

    const openDialog = () => {
        setIsOpenDialog(true)
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogTitle('Upload Credit Report')
        setDialogHeight('auto')
        props.scrollToTop()
    }

    return (
        <>
            <div className='mb-4'>
                <div className='container-lg container-fluid my-md-4'>
                    <div className='bank-state-box'>
                        <div className='row'>
                            <div className='co-12 text-center '>
                                <h1 className='boosted-score-text text-uppercase'>Get Detailed insights on your credit report</h1>
                                <p className='boosted-moneytetx'>Share the Credit Report and get insights and recommendations</p>
                            </div>
                        </div>
                        <div className='row mt-3 g-2 g-sm-4'>
                            <div className='col-sm-12 col-lg-4 px-2'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>01.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={AddBank} className='img-info' width='90' />
                                        </div>
                                        <p className='get-score-images-text text-break '>Share your credit report with us </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-2'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>02.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={GetInsights} className='img-info' width='90' />
                                        </div>
                                        <p className='get-score-images-text text-break'>Let us analyze your credit report  </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4 px-2'>
                                <div className='bankbox-card'>
                                    <div className='bankbox-inner-card g-2'>
                                        <h4 className='numbers'>03.</h4>
                                        <div className='images-boxes mx-3  my-sm-0 my-md-0'>
                                            <img src={Finances} className='img-info' width='90' />
                                        </div>
                                        <p className='get-score-images-text text-break'>get insights and recommendations  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 g-3'>
                            <div className='col-12 text-center my-3'>
                                <button id="get_credit_score_reports" className='mb-0 upload-statement-btn' onClick={() => { props.onClickCreditCall() }}>Get Credit Score Report</button>
                            </div>

                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <div className='hr-line w-50'></div>
                                <p className='m-0 p-0 ps-3 pe-3'>or</p>
                                <div className='hr-line w-50'></div>
                            </div>


                            {isOpenDialog && (
                                <ReactDialogBox
                                    closeBox={closeDialog}
                                    headerBackgroundColor='white'
                                    headerTextColor='black'
                                    headerHeight='65px'
                                    closeButtonColor='black'
                                    bodyBackgroundColor='white'
                                    bodyTextColor='black'
                                    bodyHeight={dialogHeight}
                                    headerText={dialogTitle}
                                    borderRadius='8px'
                                >
                                    <CreditReport closeDialog={closeDialog} setScore={props.setScore} setAnalysisReportData={props.setAnalysisReportData} setDialogTitle={setDialogTitle} setDialogHeight={setDialogHeight} setCreditReportData={props.setCreditReportData} bureauDataGenerate={props.bureauDataGenerate} setMessage={props.setMessage} />
                                </ReactDialogBox>)}

                            <div className='col-12 text-center my-3'>
                                <button id="upload_credit_report_dialog_open" className='mb-0 upload-statement-btn upload-statement-btn-white' onClick={() => { openDialog() }}>Upload Credit Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BankStatment;