import { useEffect, useState } from 'react';
import '../../../App.css';

const ExperianTerms = (props) => {
    const [clientName, setClientName] = useState('')

    useEffect(() => {
        var url = new URL(window.location.href);
        var searchParams = url.searchParams;
        var clientName = searchParams.get('clientName');
        if (clientName != null) {
            if (clientName != undefined) {
                setClientName(clientName == '' ? '' : `and ${clientName}`)
            }
        }
    }, [clientName])


    return (
        <>
            <div className="container">

                <h2 className={props.openFromBM ? "mt-4 mb-3" : "mt-2 mb-3"}>Experian Terms & Conditions</h2>

                <p>
                    CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH
                    EXPERIAN This End User Agreement (the “Agreement”) is made
                    between you (the “User” or “You”) and Boost.Money Private
                    Limited (hereinafter referred to as “Boost.Money”), {clientName} a private
                    limited company having its registered office at Finboost
                    Software Private Limited, #60, BHIVE Workspace Whitefield
                    Campus, Plot No. 77, Jbr Tech Park, 6th Rd, Whitefield, EPIP
                    Zone, Whitefield, Bengaluru, Karnataka 560066 (“Boost.Money”,
                    “Us” or “We”, which term shall include its successors and
                    permitted assigns). The User and Boost.Money {clientName} shall be
                    collectively referred to as the “Parties” and individually as
                    a “Party”.
                </p>

                <p>
                    I hereby consent to Boost.Money {clientName} being appointed as my
                    authorized representative to receive my Credit Information
                    from Experian for the purpose of Credit Assessment to advise
                    me on the financial journey, on ongoing basis for not
                    exceeding a period of six (6) months.
                </p>

                <p>
                    BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY
                    AGREEING TO ACCESS THE EXPERIAN CREDIT INFORMATION REPORT AND
                    CREDIT SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND
                    DETAILS (AS DEFINED BELOW)(TOGETHER REFERRED AS “CREDIT
                    INFORMATION”). YOU HEREBY ALSO CONSENT TO SUCH CREDIT
                    INFORMATION BEING PROVIDED BY EXPERIAN TO YOU AND Boost.Money
                    {clientName} BY USING EXPERIAN TOOLS, ALGORITHMS AND DEVICES AND YOU HEREBY
                    AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND CONDITIONS SET
                    FORTH HEREIN.
                </p>

                <p>Terms and Conditions:</p>

                <p>
                    Information Collection, Use, Confidentiality, No-Disclosure
                    and Data Purging
                </p>

                <p>
                    Boost.Money {clientName} shall access your Credit Information as your
                    authorized representative and Boost.Money {clientName} shall use the Credit
                    Information for limited End Use Purpose consisting of and in
                    relation to the services proposed to be availed by you from
                    Boost.Money {clientName}. We shall not aggregate, retain, store, copy,
                    reproduce, republish, upload, post, transmit, sell or rent the
                    Credit Information to any other person and the same cannot be
                    copied or reproduced other than as agreed herein and in
                    furtherance to applicable law including the CICRA.
                </p>

                <p>
                    The Parties agree to protect and keep confidential the Credit
                    Information both online and offline.
                </p>

                <p>
                    The Credit Information shared by you, or received on by us on
                    your behalf shall be destroyed, purged, erased immediately
                    upon the completion of the transaction/ End Use Purpose for
                    which the Credit Information report was procured, this period
                    not being longer than 6 months.
                </p>

                <p>
                    Governing Law and Jurisdiction:
                </p>

                <p>
                    The relationship between you and Boost.Money {clientName} shall be governed
                    by laws of India and all claims or disputes arising there from
                    shall be subject to the exclusive jurisdiction of the courts
                    of Mumbai.
                </p>

                <p>Definitions:</p>

                <p>
                    Capitalised terms used herein but not defined above shall have
                    the following meanings:<br></br>"Business Day" means a day
                    (other than a public holiday) on which banks are open for
                    general business in Mumbai.
                    <br></br>"Credit Information Report" means the credit
                    information / scores/ aggregates / variables / inferences or
                    reports which shall be generated by Experian; “Credit Score”
                    means the score which shall be mentioned on the Credit
                    Information Report which shall be computed by Experian.
                </p>

                <p>
                    “CICRA” shall mean the Credit Information Companies
                    (Regulation) Act, 2005 read with the Credit Information
                    Companies Rules, 2006 and the Credit Information Companies
                    Regulations, 2006, and shall include any other rules and
                    regulations prescribed thereunder.
                </p>

                <p>
                    PLEASE READ THE ABOVEMENTIONED TERMS AND CONDITIONS AND CLICK
                    ON “ACCEPT” FOLLOWED BY THE LINK BELOW TO COMPLETE THE
                    AUTHORISATION PROCESS/ FOR SHARING OF YOUR CREDIT INFORMATION
                    BY EXPERIAN WITH Boost.Money {clientName} IN ITS CAPACITY AS YOUR
                    AUTHORISED REPRESENTATIVE. BY CLICKING “ACCEPT” YOU AGREE AND
                    ACCEPT THE DISCLAIMERS AND TERMS AND CONDITIONS SET OUT
                    HEREIN.
                </p>

                <p>Accept Decline</p>

                <p>
                    ** This document is an electronic record in terms of the
                    Information Technology Act, 2000 and Rules made there under,
                    and the amended provisions pertaining to electronic records.
                </p>

            </div>
        </>
    );
}

export default ExperianTerms;