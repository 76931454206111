import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import '../../App.css';
import './dashboard.css';
import ScoreCards from './Header-Page/Scroe-card';
import MenuBar from './Menubar/menubar';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from './SideBar/sidebar';
import Footer from '../landing-page/Footer/Footer';
import TabComponent from '../Component/tab-component';


function DashBoard() {

    const scrollToTop = () => {
        var d = document.getElementById("scoreDetails");
        d.scrollTo({ top: 0, behavior: 'smooth' })
    };

    return (
        <>
            <MenuBar />
            <div className='sidebar-box sidnav-box'>
                <Sidebar></Sidebar>
                <div id='scoreDetails' className="sidebat-inner-box sidnav-inner-box">
                    <TabComponent index={0}></TabComponent>
                    <ScoreCards scrollToTop={scrollToTop} />
                    <ToastContainer />
                </div>
            </div>
        </>
    );
}

export default DashBoard;