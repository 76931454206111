import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import './early-access.css'
import '../../../../App.css'
import '../Slider.css'

function EarlyButtons() {
    return (
        <>
            <div className=''>
                <a id="request_early_access" className='btn-solid-lg early-access-button' href="#contact">Request early access <span className='mx-1'></span>
                    <FontAwesomeIcon icon={faArrowRightLong} />
                </a>
            </div>
        </>
    );
}

export default EarlyButtons;