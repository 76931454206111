import "./progress.css";
import Footer from "./footer";
import { useState, useEffect } from "react";

const Blue = require("../../images/assets/blue.png");
// const Red = require("../../images/assets/red.png");
// const Loader = require("../../images/assets/spinner.gif");
const LoadingSpinner = require("../../images/assets/loading_spinner.gif");

function Progress(props) {

  const [consentReject, setConsentReject] = useState(false);

  const [creditReportProgress, setCreditReportProgress] = useState(true);

  const [startFlowSDK, setstartFlowSDK] = useState(false);

  const [myStatus, setStatus] = useState("");


  const [statusArray, setStatusArray] = useState([
    {
      name: "CONSENT_APPROVED",
      status: "COMPLETED" // IN PROGRESS // COMPLETED
    },
    {
      name: "FI_REQUEST_SENT_SUCCESSFUL",
      status: "INPROGRESS" // IN PROGRESS // COMPLETED
    },
    {
      name: "FI_FETCHED_COMPLETED",
      status: "PENDING"  // IN PROGRESS // COMPLETED
    },
    {
      name: "SCORE_GENERATED",
      status: "PENDING"  // IN PROGRESS // COMPLETED
    },
    {
      name: "SCORE_READY",
      status: "PENDING"  // IN PROGRESS // COMPLETED
    }
  ]);




  useEffect(() => {

    setStatus(props.fiustatus);
    if (props.fiustatus === "" || props.fiustatus === "") {
      statusArray[0].status = "COMPLETED"
      statusArray[1].status = "INPROGRESS"
    }
    else if (props.fiustatus === "FI_REQUEST_SENT_SUCCESSFUL"
    ) {
      statusArray[1].status = "COMPLETED"
      statusArray[2].status = "INPROGRESS"
    } else if (props.fiustatus === "FI_FETCHED_COMPLETED") {
      statusArray[1].status = "COMPLETED"
      statusArray[2].status = "COMPLETED"
      statusArray[4].status = "INPROGRESS"
      // statusArray[4].status = "INPROGRESS"
    } else if (props.fiustatus === "SCORE_GENERATED" || props.fiustatus === "SCORE_NOT_GENERATED" || props.fiustatus === "GST_DATA_FETCHED") {
      statusArray[4].status = "COMPLETED"
    }

  },);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">

        <div className="pt-3">
          <h5 className="text-center">Almost there!</h5>
          <div className="pt-3"></div>
          {consentReject && <div className="progress-container">
            <div className="progress-row">
              {/* 0  CONSENT_REJECT */}
              {<div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div>}
              <div>
                <p className='progress-label'>Consent Rejected</p>
              </div>
            </div>
            <div className="progress-line-container">
              <div className="progress-line"></div>
            </div>

            {/* 1  Credit Pull Report*/}
            <div className="progress-row">
              {/* 0  CONSENT_REJECT */}
              {!creditReportProgress ? <div className="progress-step">
                <img src={Blue}
                  className="progress-tick" alt="tick" />
              </div> :
                <img src={LoadingSpinner}
                  className="loader-track" alt="tick" />
              }
              <div>
                <p className='progress-label'>{'Credit Report Pull'}</p>
              </div>
            </div>

          </div>}

          {!consentReject && <div className="progress-container">
            <div className="progress-row">
              {/* 0  CONSENT_APPROVED */}
              {statusArray[0].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[0].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[0].status !== "COMPLETED" ? 'progress-label-default' :
                  'progress-label'}>Consent Approved</p>
              </div>
            </div>
            <div className="progress-line-container">
              <div
                className={
                  statusArray[0].status === "COMPLETED" ? "progress-line" : "progress-line-dashed"
                }
              ></div>
            </div>

            {/* 1 FI_REQUEST_SENT */}
            <div className="progress-row">
              {statusArray[1].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[1].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[1].status !== "COMPLETED" ? 'progress-label-default' : 'progress-label'}>Fetching Data</p>
              </div>
            </div>

            <div className="progress-line-container">
              <div
                className={
                  statusArray[1].status === "COMPLETED" ? "progress-line" : "progress-line-dashed"
                }
              ></div>
            </div>

            {/* 2  FI_FETCH_COMPLETED */}
            <div className="progress-row">
              {statusArray[2].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[2].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[2].status !== "COMPLETED" ? 'progress-label-default' : 'progress-label'}>Analyzing Data</p>
              </div>
            </div>

            <div className="progress-line-container">
              <div
                className={
                  statusArray[2].status === "COMPLETED" ? "progress-line" : "progress-line-dashed"
                }
              ></div>
            </div>


            {/* 3 SCORE_GENERATED*/}
            <div className="progress-row">
              {statusArray[4].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[4].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[4].status !== "COMPLETED" ? 'progress-label-default' : 'progress-label'}>Generating Score</p>
              </div>
            </div>

            {/* {<div className="progress-line-container">
              <div
                className={
                  statusArray[3].status === "COMPLETED" ? "progress-line" : "progress-line-dashed"
                }
              ></div>
            </div>} */}

            {/* 4 SCORE_READY*/}
            {/* {props.creditInformation && <div className="progress-row">
              {statusArray[4].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[4].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[4].status !== "COMPLETED" ? 'progress-label-default' : 'progress-label'}>
                  {startFlowSDK ? "Credit Report Pull" : "Score Ready!"}
                </p>
              </div>
            </div>} */}
            {/* {<div className="progress-row">
              {statusArray[4].status === "COMPLETED" ? <div className="progress-step">
                <img src={Blue} className="progress-tick" alt="tick" />
              </div> : (statusArray[4].status === "INPROGRESS" ?
                <img src={LoadingSpinner} className="loader-track" alt="tick"></img> :
                <div className="progress-step-blank"></div>)}
              <div>
                <p className={statusArray[4].status !== "COMPLETED" ? 'progress-label-default' : 'progress-label'}>
                  Score Ready!
                </p>
              </div>
            </div>} */}

          </div>}


          <div className="py-2">
            <Footer />
          </div>

        </div>
      </div >
    </>
  );
}

export default Progress;
