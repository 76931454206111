import React from 'react'
import '../../../App.css';
import Header from '../../landing-page/header/header';
import Footer from '../../landing-page/Footer/Footer';
import PrivacyPolicy from '../../landing-page/Component/privacypolicy';
const Logo = require("../../../images/assets/Logo.png");

const Privacypolicy = () => {
    return (
        <>
            <Header></Header>
            <PrivacyPolicy openFromBM={true}></PrivacyPolicy>
            <Footer></Footer>
        </>
    )
}

export default Privacypolicy