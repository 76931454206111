// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.coming-box{
    margin-top: 35px;
  }
  
  .back-home{
    text-decoration: none;
    color: #2553F6;
    font-size: 14px;
    font-weight: 700;
  }
  
  .back-home:hover{
    text-decoration: none;
    color: #2553F6;
  }
  
  .stay-tune{
    text-transform: uppercase;
    margin-left: 10%;
  }
  
  .take-charge{
    text-transform: uppercase !important;
  }

  .demo-tetx-black{
    font-size: 14px;
    margin-left: 5px;
  }

  .waitlist-btn{
    width: 220px;
    height: 40px;
    flex-shrink: 0;
    font-size: 14px;
    color: white;
    border-radius: 12px;
    border: 0px;
    background: linear-gradient(117deg, #7624E7 -19.55%, #2553F6 84.59%);
    box-shadow: 0px 10px 30px 0px rgba(206, 206, 206, 0.60);
}`, "",{"version":3,"sources":["webpack://./src/pages/Coming-Soon/coming-soon.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,oEAAoE;IACpE,uDAAuD;AAC3D","sourcesContent":["\n.coming-box{\n    margin-top: 35px;\n  }\n  \n  .back-home{\n    text-decoration: none;\n    color: #2553F6;\n    font-size: 14px;\n    font-weight: 700;\n  }\n  \n  .back-home:hover{\n    text-decoration: none;\n    color: #2553F6;\n  }\n  \n  .stay-tune{\n    text-transform: uppercase;\n    margin-left: 10%;\n  }\n  \n  .take-charge{\n    text-transform: uppercase !important;\n  }\n\n  .demo-tetx-black{\n    font-size: 14px;\n    margin-left: 5px;\n  }\n\n  .waitlist-btn{\n    width: 220px;\n    height: 40px;\n    flex-shrink: 0;\n    font-size: 14px;\n    color: white;\n    border-radius: 12px;\n    border: 0px;\n    background: linear-gradient(117deg, #7624E7 -19.55%, #2553F6 84.59%);\n    box-shadow: 0px 10px 30px 0px rgba(206, 206, 206, 0.60);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
