import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginAction } from '../../../redux/actions/auth_action';
import config from '../../../config.json';
import '../../../App.css';
import './login.css'
import { useSelector } from 'react-redux';
import Loader from '../../loader/loader';
import Footer from '../../landing-page/Footer/Footer'
import ExpLogoFooter from '../../landing-page/Component/exp_logo_footer'
const Logo = require("../../../images/assets/Logo.png");

function Login() {
    const [mobileNumber, setMobileNumber] = useState("")
    const [loader, setLoader] = useState(false);
    const [showError, setShowError] = useState(false);
    const [userExists, setUserExists] = useState();

    const { user } = useSelector(state => {
        return state.authReducer;
    });
    const navigate = useNavigate();

    useEffect(() => {
        const pathName = window.location.pathname;

        if(config?.env === "PROD" && pathName === "/login"){
            navigate('/comingsoon');
        }

        if (user?.access_token) {
            navigate('/dashboard');
        }
    }, [])


    const login = () => {
        setUserExists('')
        setShowError(false)
        if (mobileNumber === undefined || mobileNumber === '' || mobileNumber.length !== 10) {
            setShowError(true)
        } else {
            const param = {
                "mobileNumber": mobileNumber
            };
            setLoader(true)
            loginAction(param).then(resp => {
                setLoader(false)
                navigate('/otp', { state: { mobileNumber: mobileNumber, otpReferenceId: resp.otpReferenceId, from: 'login' } });
            }).catch(error => {
                setLoader(false)
                if (
                    (error &&
                        error?.response && error?.response.data)
                ) {
                    setUserExists(error?.response.data.errorMessage)
                } else {
                    setUserExists('Something went wrong!');
                }

            })
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            login()
        }
    };

    const btnEnableDisable = {
        opacity: (mobileNumber.length !== 10 || mobileNumber == '') ? 0.5 : 1.0,
    };

    return (
        <>
            {loader && <Loader />}
            <div style={{ marginBottom: 0 }}>
                <div className='container mt-3'>
                    <div className='row g-3 mb-3 align-items-center'>
                        <div className='col-5'>
                            {/* logo-image */}
                            <a className='navbar-brand logo-images-boxes' href='/'>
                                <img src={Logo} className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
                <div className='container mt-2 mt-md-4'>
                    <div className='row main-box-border'>
                        <div className='col-sm-12 col-lg-5 px-2 px-md-0'>
                            <div className='stay-box'>
                                <div>
                                    <h1 className='color'>
                                        Sign in and get insights on your credit score
                                    </h1>
                                    <p>
                                        There is so much you can do to improve your score..
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-12 col-lg-7 mt-1 mt-sm-0 form-main-box'>
                            <div className='px-sm-1 row'>
                                <div className='form-box pt-3 col-md-8 offset-md-2'>
                                    <h1 className=' text-start mb-md-0'>
                                        Login To Your Account
                                    </h1>
                                    <p className='fw-semibold mt-md-3'>
                                        Enter Mobile Number
                                    </p>
                                    <div className='form-input-box login-box'>
                                        <form>
                                            <div className='form-inner-box mb-md-5'>
                                                <div className='mb-1 mb-md-2'>
                                                    <label className='label-label'>Mobile Number</label>
                                                </div>
                                                <div className='mb-1 mb-md-5'>
                                                    <input type="tel" minLength="10" maxLength="10" autoComplete='off' className='input-box form-control' pattern="\d{3}-\d{3}-\d{4}"
                                                        value={mobileNumber}
                                                        onChange={(e) => {
                                                            setMobileNumber(e.target.value = e.target.value.replace(/[^0-9-]/g, ''))
                                                        }}
                                                        onKeyDown={handleKeyDown}
                                                    />
                                                    {(showError && (mobileNumber === undefined || mobileNumber === '')) && <span className="demo-tetx">Please enter your mobile number</span>}
                                                    {(showError && (mobileNumber.length !== 10 && mobileNumber !== '')) && <span className="demo-tetx">Please enter valid mobile number</span>}
                                                    {userExists !== '' && <span className="demo-tetx mt-2">{userExists}</span>}
                                                </div>
                                            </div>
                                            <div className='form-inner-box form-button-box mt-1 mt-md-5'>
                                                <div className='row g-2 align-items-center'>
                                                    <div className='col-12 col-md-6 order-2 order-md-1'>
                                                        <div className='text-center text-sm-start my-2 my-sm-0'>
                                                            <span>
                                                                <span className='new-user-text'>New user? </span>
                                                                <Link className='new-user-sign-up-text' to={'/signup-prod'}>
                                                                    <span>Sign Up</span>
                                                                </Link>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-6 order-1 order-md-2 text-end'>
                                                        <div className='text-end my-2 my-sm-0'>
                                                            <button type='button' className='next-button'
                                                                style={btnEnableDisable}
                                                                onClick={() => {
                                                                    login();

                                                                }}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                        <div className="pt-4">
                                            <ExpLogoFooter />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footer-box'>
                <Footer />
            </div>
        </>
    );
}

export default Login;