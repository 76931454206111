import React, { useState, useRef, forwardRef } from "react";
import withClickOutside from "./WithClickOutside";
import AsyncSelect from 'react-select';
import { customStyles } from "../lang-support/lang-style";
import './download-report-btn.css'
import DLReportOptionItems from "./dl-report-option-items";
import LanguageSelector from "../lang-support/lang-selector";
const DL_IC = require('../../../images/assets/dl_ic.png');
const LANG_IC = require('../../../images/assets/language.png');
const options = [
    { value: 'en', label: 'English' },
    { value: 'hi', label: 'Hindi' },

]

const DownloadReportBtn = forwardRef(({ showDropDown, setShowDropDown, btnLabel, clickOnDownload, clickOnExcel }, ref) => {

    const [selectedLanguage, setSelectedLanguage] = useState({ value: 'en', label: 'English' });

    const handleLanguageChange = (selectedOption) => {
        // Update the state with the new selected language
        setSelectedLanguage(selectedOption);
    };
    const clickDownload = (e) => {
        setShowDropDown(!showDropDown);
        clickOnDownload(selectedLanguage.value ?? 'en');
    }
    const clickExcel = (e) => {
        setShowDropDown(!showDropDown);
        clickOnExcel();
    }
    return (
        <section ref={ref}>
            <div className="dropdown">
                <div className='download-btn-dropdown dropdown-toggle'
                    onClick={() => setShowDropDown(!showDropDown)}
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded={{ showDropDown }}>
                    {btnLabel}
                </div>
                <div className={"dropdown-menu" + (showDropDown ? " show" : "")} aria-labelledby="dropdownMenuButton">
                    <div className="dl-report-options">
                        <div className="dl-report-item-group"
                            onClick={clickDownload}>
                            <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Boost Report
                                </p>
                                <p className="dl-report-subline">
                                    Consolidated credit and boost
                                    report in PDF format.
                                </p>
                            </div>
                        </div>
                        {/* <LanguageSelector options={options} customStyles={customStyles} onLanguageChange={handleLanguageChange} /> */}
                        <div className="dl-break-line"></div>
                        <div className="dl-report-item-group"
                            onClick={clickExcel}>
                            <img src={DL_IC} alt="DL_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Excel Report
                                </p>
                                <p className="dl-report-subline">
                                    List of all your bank transaction
                                    in Excel format.
                                </p>
                            </div>
                        </div>
                        {/* <div className="dl-break-line"></div>
                        <div className="dl-report-item-group" onClick={() => setShowDropDown(!showDropDown)}>
                            <img src={SHARE_IC} alt="DL_IC" className="dl-report-ic" />
                            <div className="ps-2">
                                <p className="dl-report-label">
                                    Share Report
                                </p>
                                <p className="dl-report-subline">
                                    Share credit report with banks
                                    and lenders.
                                </p>
                            </div>
                        </div> */}

                        {/* USE BELOW COMMON COMPONENT INSTEAD OF ABOVE OPTIONS */}
                        {/* <DLReportOptionItems icon={DL_IC} label="Download Docs" subline="Share credit report with banks
                            and lenders."
                            setClickOnItem={() => {
                                /// event for click
                            }}>
                        </DLReportOptionItems> */}

                    </div>
                </div>
            </div>
        </section>
    );
});

export default withClickOutside(DownloadReportBtn);