import React from 'react';
import '../../../App.css'
import './Footer.css'
import ChatIcon from '../../Boost-AI/Chat-Icon/chat-icon';
const BoostWhiteLogo = require("../../../images/boost-logo-white.png");
const FbLogo = require("../../../images/Facebook.png");
const LinkLogo = require("../../../images/Linkedin.png");
const TwitterLogo = require("../../../images/Twitter.png");
function Footer() {

    return (
        <>
            <ChatIcon></ChatIcon>
            <div className="copyright">
                <div className="container-lg container-fluid">
                    <div className="row">
                        <div className="col-sm-12 py-2 privacy-block row g-2">
                            <div className="col-sm-6 term">
                                <a id="term_and_conditions_link" className="p-small statement" href='/terms'>Terms & Conditions</a>
                                <a id="privacy_policy_link" className="p-small statement" href='/privacypolicy'>  |  Privacy Policy</a>
                                <a id="google_api_disclosure_link" className="p-small statement" href='/privacypolicy#google-api-disclosure'>  |  Google API Disclosure</a>
                            </div>
                            <div className="col-sm-6 copyright-state">
                                <a id="copyright_boost_money_link" className="p-small statement">Copyright © {(new Date().getFullYear())} Boost.Money</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Footer;