import React from 'react';
import { useState } from 'react';
import { ReactDialogBox } from 'react-js-dialog-box'
import ChatAI from '../Chat/chatai';
import './middle-cd.css'

const INFO_IC = require("../../../images/assets/info.png");
const CHAT_IC = require("../../../images/assets/msg_ic.png");
const PRIVACY_IC = require("../../../images/assets/privacy.png");

const MiddleContentCD = (props) => {

    // const[showChatIcon,setShowChatIcon] = useState(false)
    const[showChatAIBox,setShowChatAIBox] = useState(false)
    const [dialogHeight, setDialogHeight] = useState('auto');
    const [dialogTitle, setDialogTitle] = useState('BOOST AI')
    
    const openChatAI = () =>{
        //props.closeInfoDialog()
        //props.setShowChatAIInfoBox(false)
        props.setShowChatIcon(false)
        setShowChatAIBox(true)
        setDialogTitle('BOOST AI')
        setDialogHeight('auto')
        //props.closeInfoDialog()    
    }

    const closeChatDialog = () => {
        props.setShowChatAIInfoBox(false)
        // setShowChatAIBox(false)
        props.setShowChatIcon(true);
        setDialogTitle('BOOST AI')
        setDialogHeight('auto')
    }

    return (
        <>
        <div className="container bg-content-center mt-2">
            <p className="tips-txt pt-3">Tips for getting started</p>
            <div className='hr-white-line'></div>
            <div className="col-12 d-flex flex-wrap pt-4 ps-4 pe-4 pb-3 justify-content-between">
                <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                    <div className="img-txt">
                        <img src={CHAT_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Ask Boost AI</p>
                    </div>
                    <p className="sub-desc pt-2 ps-1">BOOST AI can answer your questions related to your financial data like Bank transactions, Tax  and Credit Report.</p>
                </div>

                <div className='col-lg-3 col-md-4 col-sm-12 pt-3'>
                    <div className="img-txt">
                        <img src={PRIVACY_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Sensitive data</p>
                    </div>
                    <p className="sub-desc pt-2 ps-1">Your conversations are processed by human reviewers to improve Boost AI. Don’t enter anything you wouldn’t want reviewed or used.</p>
                </div>

                <div className='col-lg-3 col-md-3 col-sm-12 pt-3'>
                    <div className="img-txt">
                        <img src={INFO_IC} alt="sub-icon" className="sub-icon" />
                        <p className="sub-title">Check your facts</p>
                    </div>
                    <p className="sub-desc pt-2 ps-1">We have safeguards, but sometimes Boost AI can make mistakes.</p>
                </div>

            </div>
            <div className="py-4">
                <button onClick={() =>{openChatAI()}} id="lets_get_started_ai_chat" className="upload-statement-btn wd-100">Let’s get started</button>
            </div>
        </div>

        {showChatAIBox && (
                <div className='chat-dialog'>
                    <ReactDialogBox
                        headerBackgroundColor='white'
                        headerTextColor='black'
                        closeButtonColor='black'
                        bodyBackgroundColor='white'
                        bodyTextColor='black'
                        bodyHeight={dialogHeight}
                        borderRadius='8px'
                        headerHeight='65px'
                        headerText={dialogTitle}
                        closeBox={closeChatDialog}
                    >
                    <ChatAI setShowChatAIBox={setShowChatAIBox} setShowChatIcon={props.setShowChatIcon}></ChatAI>
            </ReactDialogBox> </div> )}

    </>);
}

export default MiddleContentCD;