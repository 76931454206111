import {React,useState,useEffect} from 'react';
import './error-message.css'
import '../../../App.css'
import 'react-dropdown/style.css';
import { doc } from '@firebase/firestore';


const ErrorMessage = (props) => {
     let success= "";
     let error = "";

     if(props?.message && props?.message?.success){
        success = props?.message?.success;
     }else if(props?.message && props?.message?.error){
        error = props?.message?.error;
     }else if(props?.state && props?.state?.success){
        success = props?.state?.success;
     }else if(props?.state && props?.state?.error){
        error = props?.state?.error;
     }


    return (
        <>
            {(error !="" || success  !="") && <div className='container-lg container-fluid my-3 alert-msg-box'>
                {success && success !="" && <div className="alert show-success-main-box alert-success d-flex align-items-center w-100 justify-content-between">
                    <div className='d-flex align-items-center align-items-sm-center'>
                        <i className="bi bi-check-circle success-exclamations-icons"></i>
                        <p className='mb-0 ms-3 error-message-text-email'>{success} </p>
                    </div>
                    <button type="button" className="success-mess-close-box" data-bs-dismiss="alert"> <i className="bi bi-x-circle circle-close-box"></i></button>
                </div>}
                {error && error !="" && <div className="alert show-error-main-box alert-success d-flex align-items-center w-100 justify-content-between">
                    <div className='d-flex align-items-center align-items-sm-center'>
                        <i className="bi bi-check-circle exclamations-icons"></i>
                        <p className='mb-0 ms-3 error-message-text-email'>{error} </p>
                    </div>
                    <button type="button" className="error-mess-close-box" data-bs-dismiss="alert"><i className="bi bi-x-circle circle-close-box"></i></button>
                </div>}
            </div>}
        </>
    )
}

export default ErrorMessage;