import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import Tooltip from '../../../Component/tool-tip';
const SpendingLogo = require("../../../../images/assets/top_spending.png");

const TopCategory = (props) => {

  let categorieName = [];
  let totalAmount = [];
  let spendingPer = [];
  let topSpendingCategoryData = props.topSpendingCategoryData ? props.topSpendingCategoryData : null

  useEffect(() => {
    {
      topSpendingCategoryData && topSpendingCategoryData.categoryData && topSpendingCategoryData.categoryData.forEach((spendingData) => {
        categorieName.push(spendingData.categoryName)
        totalAmount.push(spendingData.totalAmount)
        spendingPer.push(parseInt(spendingData.spendingPercentage))
      })
    }
  })

  const pieChart = {
    series: spendingPer,
    options: {
      chart: {
        width: '55%',
        type: 'pie',
      },
      labels: categorieName,
      theme: {
        monochrome: {
          enabled: true
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: -5,
          }
        }
      },
      fill: {
        colors: ['#334BF3', '#8593F8', '#ADB7FA', '#D6DBFD', '#dddff0']
      },
      tooltip: {
        enabled: true,
        intersect: true,
        shared: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const categoryName = w.globals.labels[seriesIndex].toLowerCase()
          const categoryAmount = totalAmount[seriesIndex]
          return '<div class="arrow_box">' +
            '<p class="arrow-text">' + categoryName + '</p>' +
            '<p class="arrow-amt">' + '₹ ' + categoryAmount.toLocaleString() + '</p>' +
            '</div>'
        }
      },
      dataLabels: {
        formatter(val, opts) {
          const dt = opts.w.globals.series[opts.seriesIndex]
          return [dt + "%"]
        }
      },
      legend: {
        show: false
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              height: 250
            },
          }
        }
      ]
    },
  };

  return (
    <>
      {topSpendingCategoryData && topSpendingCategoryData.data != null &&
        <div className='col-6 pb-category'>
          <div className='account-details-box'>
            <div className="accordion pie-chart">
              <p className="acc-info-text mb-0">TOP SPENDING CATEGORIES
                <Tooltip content="Categories on which you have been spending"> <i className="bi bi-info-circle boost-info-icon me-1"></i></Tooltip></p>

              <p className="subtitle-heading-chart">{topSpendingCategoryData && topSpendingCategoryData?.data?.percentageText != null ? '(' + topSpendingCategoryData.data.percentageText + ')' : ''}</p>
              <div className='dividers w-100'></div>

              <div className="top-chart-logo">
                <img src={SpendingLogo} className='img-fluid' />
                <div className="top-chart-text">
                  <p className="acc-info-text mb-0">{topSpendingCategoryData && topSpendingCategoryData?.data?.name != null ? topSpendingCategoryData.data.name + ":" + " ₹" + topSpendingCategoryData.data.amount : ''}</p>
                  <p className="subtitle-heading-chart">{topSpendingCategoryData && topSpendingCategoryData?.data?.label != null ? topSpendingCategoryData.data.label : ''}</p>
                </div>
              </div>
              <ReactApexChart className="resize-chart" options={pieChart.options} series={pieChart.series} type="pie" height={props?.flow === 'savings' ? 425 : 335} />
            </div>
          </div>
        </div>}
    </>
  )
}

export default TopCategory