import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './savings.css'
import Footer from '../progress/footer';
import Header from '../landing-page/header/header';
import MenuBar from '../Dashboard/Menubar/menubar';
import Sidebar from '../Dashboard/SideBar/sidebar';
import AliceCarousel from 'react-alice-carousel';
import { boostScoreReport } from '../../redux/actions/dashboard_action';
import Select, { components } from 'react-select';
import { customStyles } from '../Dashboard/Header-Page/components/custom-style-component-bureau';
import Loader from '../loader/loader';
import Dropdown from 'react-dropdown';
import '../../App.css';
import '../boost-score/boost-score.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import SavingAnalysis from '../boost-score/Component/Graph/saving-analysis';
import TopCategory from '../boost-score/Component/Graph/top-category';
const BankLogo = require("../../images/Bank/Bank.png");

const SAVINGS = require("../../images/assets/saving.png");
const NoSavingThumb = require("../../images/no_saving_thumb.png");

const SALES = require("../../images/assets/sales.png");
const EXPORT = require("../../images/assets/export.png");
const ENTER = require("../../images/assets/enter.png");
const WALLET = require("../../images/assets/wallet.png");

const Savings = () => {

  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [incomeVsExpenseData, setIncomeVsExpenseData] = useState();
  const [topSpendingCategoryData, setTopSpendingCategoryData] = useState();
  const [analysisReportData, setAnalysisReportData] = useState();
  const [insightsItem, setInsightsItem] = useState([])
  const [bankList, setBankList] = useState([])
  const [newBankName, setNewBankname] = useState()
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [monthlyCashflowList, setMonthlyCashflowList] = useState([]);
  // const [monthlyCashflowSelectedObj, setMonthlyCashflowSelectedObj] = useState({});
  const [selMonthYearDataObj, setSelMonthYearDataObj] = useState({
    selMonth: null, selYear: null,
    selMonthlyCashflowObj: {}
  });
  const [loader, setLoader] = useState(false);
  const { state } = useLocation();

  const { user } = useSelector(state => {
    return state.authReducer;
  });

  let bankIndex = 0;
  let bankNameList = [];

  useEffect(() => {
    if (!user?.access_token) {
      navigate('/');
    } else {

      // const data = getUserData()
      // setUserData(data)
      getBoostScoreReport(bankIndex);

    }
  }, [])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  })


  const getBoostScoreReport = async (index) => {
    setLoader(true)
    await boostScoreReport().then((resp) => {
      if (bankList.length === 0) {
        getBankList(resp.accounts)
      }
      const resAccount = resp.accounts[index];
      const monthlyCashflows = resAccount.analysis.monthlyCashFlows;
      setMonthlyCashflowList(monthlyCashflows);

      if (monthlyCashflows != null && monthlyCashflows.length > 0) {
        const tempYearList = monthlyCashflows.map(item => item.year);
        const yfList = [...new Set(tempYearList)];
        setYearList(yfList);
        // Pass the correct parameters to filterMonthByYear
        filterMonthByYear(monthlyCashflows, yfList[yfList.length - 1]);
      } else {
        setSelMonthYearDataObj({
          selMonth: null, selYear: null,
          selMonthlyCashflowObj: null
        })
      }
      setAnalysisReportData(resAccount.analysis)
      setIncomeVsExpenseData(resAccount.analysis.incomeVsExpenseAnalysis);
      setTopSpendingCategoryData(resAccount.analysis.categoryAnalysis);
      // insightsAnalysisReports(resAccount.analysis);
      setLoader(false)
      //window.dispatchEvent(new Event('resize'))
    }).catch((error) => {
     
      setLoader(false)
    })
  }

  const filterMonthByYear = (monthlyCashflow, selectedYear) => {
    if (monthlyCashflow.length > 0) {
      const tempMonthList = monthlyCashflow
        .filter(item => item.year === selectedYear)
        .map(item => item.month);
      setMonthList(tempMonthList);
      filterMonthlyCashflowObj(monthlyCashflow, selectedYear, tempMonthList[tempMonthList.length - 1]);
    }
  }

  const filterMonthlyCashflowObj = (monthlyCashflow, selectedYear, selectedMonth) => {
    if (monthlyCashflow.length > 0) {
      const monthCashFlowObj = monthlyCashflow.find(item => item.year === selectedYear &&
        item.month === selectedMonth);
      setSelMonthYearDataObj({
        selMonth: selectedMonth, selYear: selectedYear,
        selMonthlyCashflowObj: monthCashFlowObj
      })
    }
  }

  const getBankList = (bankLists) => {
    if (newBankName) {

      bankNameList.unshift(newBankName)
    }
    if (bankLists) {
      for (let index = 0; index < bankLists.length; index++) {
        bankNameList.push(bankLists[index].analysis.accountDetail.bankName + " Bank | " + bankLists[index].analysis.accountDetail.accountType + ' Account - ' + bankLists[index].analysis.accountDetail.accountNumber.slice(-4));
      }
    }
    setBankList(bankNameList)
  }

  const responsives = {
    // 0: {
    //   items: 2
    // },
    // 375: {
    //   items: 1.5
    // },
    // 767: {
    //   items: 2
    // },
    // 1024: {
    //   items: 5,
    //   itemsFit: 'contain',
    //   disableButtonsControls: 'true',
    //   infinite: 'true'
    // },
    0: {
      items: 1.8
    },
    360: {
      items: 2
    },
    375: {
      items: 2
    },
    390: {
      items: 2.1
    },
    412: {
      items: 2.2
    },
    414: {
      items: 2.2
    },
    430: {
      items: 2.3
    },
    540: {
      items: 3,
    },
    576: {
      items: 3
    },
    768: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    820: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    1024: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
    1200: {
      items: 4,
      itemsFit: 'contain',
      disableButtonsControls: 'true',
      infinite: 'true'
    },
  };

  const Control = ({ children, ...props }) => {
    const [inputValue, setInputValue] = useState('');
    const { selectProps, hasValue } = props;

    return (
      <components.Control {...props}>
        <div style={{ position: 'relative', display: 'flex', }}>
          {hasValue || inputValue ? (
            <label
              style={{
                position: 'absolute',
                left: '6px',
                top: '-24px',
                backgroundColor: 'white',
                padding: '0 4px',
                fontSize: '80%',
                fontWeight: '400',
                fontFamily: 'Poppins',
                color: '#000000',
              }}
            >
              {selectProps.placeholder}
            </label>
          ) : null}
          {children}
        </div>
      </components.Control>
    );
  };

  // const insightsAnalysisReports = (analysisReportData) => {
  //   var i = [];
  //   analysisReportData?.ratio?.forEach(boostData => {
  //     i.push(
  //       <div className="col insights-main-box-height" key={boostData.status}>
  //         {console.log(boostData.status)}
  //         <div className='insights-sub-main-box h-100'>
  //           <div className="cards h-100">
  //             <div className="card-bod px-2 pt-3 pb-2">
  //               <div className='insights-imag-box mb-2'>
  //                 {console.log(boostData.label)}
  //                 {(boostData && boostData.label === 'Income to Expense') ? <img src={Expense} className='img-fluid pb-1' alt="expense" /> : ""}
  //                 {(boostData && boostData.label === 'Income to Investments') ? <img src={Investments} className='img-fluid pb-1' alt="investments" /> : ""}
  //                 {(boostData && boostData.label === 'Income to Obligations') ? <img src={Obligation} className='img-fluid pb-1' alt="obligation" /> : ""}
  //                 {(boostData && boostData.label === 'Income to Insurance') ? <img src={Insurance} className='img-fluid pb-1' alt="insurance" /> : ""}
  //               </div>
  //               <h5 className="card-title insights-box-main-title p-0 m-0 truncate">{(boostData && boostData !== null) ? (boostData?.label && boostData?.label != null) ? boostData.label : '' : ''}</h5>
  //             </div>
  //             <div className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'}>
  //               <p className="card-text insights-card-main-text p-0 m-0">{(boostData && boostData !== null) ? (boostData.status && boostData.status !== null) ? boostData?.status : '' : ''}</p>
  //               <div className='d-flex justify-content-between align-items-center mt-1'>
  //                 <h5 className={(boostData?.status ?? '').toLowerCase() === 'good' || (boostData?.status ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (boostData?.status ?? '').toLowerCase() === 'poor' || (boostData?.status ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (boostData?.status ?? '').toLowerCase() === 'average' || (boostData?.status ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(boostData && boostData !== null) ? (boostData?.ratio && boostData?.ratio !== null) ? (boostData?.ratio) : '' : ''}</h5>
  //                 <i className="bi bi-chevron-right right-ariw-icons"></i>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   });
  //   setInsightsItem(i)
  // }



  return (
    <>
      {loader && <Loader />}
      <MenuBar />
      <div className='sidebar-box sidnav-box'>
        <Sidebar></Sidebar>

        {/* Your Money in, money out & Total savings */}
        <div id='boostScore' className="pt-1 sidebat-inner-box sidnav-inner-box">
          <div className='container-lg container-fluid mt-3'>
            <h1 className='header-title mb-2 mb-sm-3 ps-2'>Savings</h1>

            {bankList.length !== 0 && <div className='container-lg container-fluid my-sm-3 pt-2'>
              <Select
                styles={customStyles}
                components={{ Control }}
                defaultValue={{ value: bankList[0], label: bankList[0] }}
                placeholder='Select Bank Account'
                isSearchable={false}
                options={bankList.map((bankNames, index) => ({
                  value: index,
                  label: bankNames,
                }))}
                onChange={(index) => {
                  bankIndex = index.value;
                  getBoostScoreReport(index.value);
                }}
                formatOptionLabel={bankNames => (
                  <div className="bank-option">
                    <img src={BankLogo} alt="dropdown-logo" className='bank-dropdown-logo' /> {bankNames.label}
                  </div>
                )}
              />
            </div>}

          </div>
          <section id='insights'>
            <div className='container-lg container-fluid my-3'>
              <div className='insights-main-box'>
                <div className='row g-2'>
                  <div className='col-12 p-0'>

                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>Savings Summary</p>

                  </div>
                </div>
                <div className='dividers w-100'></div>
                {selMonthYearDataObj.selMonthlyCashflowObj == null &&
                  <div className='p-0 pt-2 d-flex flex-wrap flex-colum'>
                    <p className='insights-heding-text-sub py-1 ps-2 ps-lg-4 ps-md-3 m-0'>
                      Your money in, money out & savings</p>
                    <div className='col-12 d-flex flex-column justify-content-center align-items-center pb-5'>
                      <img src={NoSavingThumb} alt='NoSavingThumb' className='no-saving-img' />
                      <p className='p-0 m-0 ps-2 pe-2 text-center'>
                        There are no transactions for the selected bank account</p>
                    </div>

                  </div>}


                {selMonthYearDataObj.selMonthlyCashflowObj && <div className='row p-0 m-0'>
                  <div className='p-0 pt-2 d-flex flex-wrap justify-content-between'>
                    <p className='insights-heding-text-sub py-1 ps-2 ps-lg-4 ps-md-3 m-0'>
                      Your money in, money out & savings</p>

                    <div className='d-flex flex-wrap me-4 ms-2'>
                      {monthList && monthList.length > 0 && <Dropdown options={monthList} onChange={(val) => {
                        filterMonthlyCashflowObj(monthlyCashflowList, selMonthYearDataObj.selYear, val.value)
                      }} value={monthList[monthList.length - 1]} placeholder="DEC" />}
                      <div className='ms-2 me-1'></div>
                      {yearList && yearList.length > 0 && <Dropdown options={yearList} onChange={(val) => {
                        filterMonthByYear(monthlyCashflowList, val.value);
                      }} value={yearList[yearList.length - 1]} placeholder="2024" />}
                    </div>

                  </div>
                </div>}

                {selMonthYearDataObj.selMonthlyCashflowObj && (
                  <div className='row g-2 savings-boxes '>
                    <div className='d-flex  flex-row justify-content-start px-2 pt-2 scroll-container'>
                      <div className='insights-main-box-height boxes-h px-1 px-md-2 px-lg-2'>
                        <div className='insights-sub-main-box'>
                          <div className="cards h-100">
                            <div className="card-bod px-2 pt-3 pb-4">
                              <div className='insights-imag-box-saving-ic mb-2'>
                                <img src={SAVINGS} className='img-fluid pb-1' alt="enter" />
                              </div>
                              <h5 className="card-title insights-box-main-title p-0 m-0">Money Saving</h5>
                              <h6 className="pt-1 card-month">{selMonthYearDataObj.selMonth} {selMonthYearDataObj.selYear}</h6>
                            </div>
                            <div className={'good px-2 py-3 limit-box saving-bottom-boxes px-2 py-3'}>
                              <p className="card-text insights-card-main-text p-0 m-0">Total Savings</p>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <h5 className={'card-title accounts-box-title-text'}>₹{selMonthYearDataObj.selMonthlyCashflowObj.savings}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='insights-main-box-height boxes-h px-1 px-md-2 px-lg-2'>
                        <div className='insights-sub-main-box'>
                          <div className="cards h-100">
                            <div className="card-bod px-2 pt-3 pb-4">
                              <div className='insights-imag-box mb-2'>
                                <img src={ENTER} className='img-fluid pb-1' alt="enter" />
                              </div>
                              <h5 className="card-title insights-box-main-title p-0 m-0">Money In</h5>
                              <h6 className="pt-1 card-month">{selMonthYearDataObj.selMonth} {selMonthYearDataObj.selYear}</h6>
                            </div>
                            <div className={'good px-2 py-3 limit-box saving-bottom-boxes px-2 py-3'}>
                              <p className="card-text insights-card-main-text p-0 m-0">Total Credit</p>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <h5 className={'card-title accounts-box-title-text'}>₹{selMonthYearDataObj.selMonthlyCashflowObj.moneyIn}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='insights-main-box-height boxes-h px-1 px-md-2 px-lg-2'>
                        <div className='insights-sub-main-box'>
                          <div className="cards h-100">
                            <div className="card-bod px-2 pt-3 pb-4">
                              <div className='insights-imag-box mb-2'>
                                <img src={EXPORT} className='img-fluid pb-1' alt="export" />
                              </div>
                              <h5 className="card-title insights-box-main-title p-0 m-0">Money Out</h5>
                              <h6 className="pt-1 card-month">{selMonthYearDataObj.selMonth} {selMonthYearDataObj.selYear}</h6>
                            </div>
                            <div className={'px-2 py-3 age-box saving-bottom-boxes px-2 py-3'}>
                              <p className="card-text insights-card-main-text p-0 m-0">Total Debit</p>
                              <div className='d-flex justify-content-between align-items-center mt-1'>
                                <h5 className={'card-title age-box-text-icon'}>₹{selMonthYearDataObj.selMonthlyCashflowObj.moneyOut}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}



              </div>
            </div>
          </section >

          {analysisReportData && analysisReportData.sectionData &&
            <section id='analysis' className='accountdetailchart'>
              <div className='container-lg container-fluid my-3'>
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header accordion-header accordion-button align-items-start pb-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                      <div className='w-100'>
                        <div>
                          <div className='d-flex justify-content-between'>
                            <p className="acc-info-text" >Savings analytics (6 Months) </p>
                          </div>
                        </div>

                      </div>
                    </h2>

                    <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show">
                      <div className="accordion-body">

                        <div className='row spending-category'>
                          {monthlyCashflowList && monthlyCashflowList.length > 0 &&
                            <div className='col-6 pb-2'>
                              <SavingAnalysis incomeVsExpenseData={monthlyCashflowList} />
                            </div>
                          }
                          {topSpendingCategoryData && topSpendingCategoryData.data != null &&
                            <TopCategory topSpendingCategoryData={topSpendingCategoryData} flow='savings' />

                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>}
        </div>
      </div>
    </>
  )

}
export default Savings;